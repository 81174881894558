import { connect } from 'react-redux'
import Component from './component'
import splitEvery from 'ramda/es/splitEvery'
import reduce from 'ramda/es/reduce'
import slice from 'ramda/es/slice'
import map from 'ramda/es/map'
import compose from 'ramda/es/compose'
import curry from 'ramda/es/curry'
import concat from 'ramda/es/concat'
import unnest from 'ramda/es/unnest'
import identity from 'ramda/es/identity'
import { createSelector } from 'reselect'
import { sIsFrontpage, sIsCategory } from '../../redux/modules/posts'
import { sPostIdsForCurrentRoute } from '../../redux/modules/postsByRoute'
import { sInitialPage } from '../../redux/modules/initialPage'
import { sLayoutColumns } from '../../redux/modules/browser'

const WithData = connect(state => ({
  rows: sRowsFromPostIds(state),
}))(Component)

export default WithData

const mkSize = curry((size, obj) => ({ size, ...obj }))
const mkSmall = mkSize('small')
const mkMedium = mkSize('medium')
const mkLarge = mkSize('large')

const mkType = curry((type, obj) => ({ type, ...obj }))
const mkPost = mkType('post')

const mkValue = value => ({ value })

const mkDropIn = compose(mkSmall, mkType('drop_in'), mkValue)

const mkSmallPost = compose(mkSmall, mkPost, mkValue)
const mkMediumPost = compose(mkMedium, mkPost, mkValue)
const mkLargePost = compose(mkLarge, mkPost, mkValue)

export const sRowsFromPostIds = createSelector(
  sPostIdsForCurrentRoute,
  state => sLayout(state),
  sInitialPage,
  sLayoutColumns,
  (postIds, layout, initialPage, layoutColumns) => {
    if (initialPage > 1 || !layout) {
      return compose(splitEvery(layoutColumns), map(mkSmallPost))(postIds)
    }

    const { rows: layoutRows, postsTaken } = reduce(
      ({ rows, postsTaken }, { row_layout, 'drop-in': drop_in }) => {
        let row = []
        let posts = []

        if (postsTaken >= postIds.length - 3) {
          return { rows, postsTaken }
        }

        switch (row_layout) {
          case '1/1/1':
            posts = slice(postsTaken, postsTaken + 3, postIds)
            row = map(mkSmallPost, posts)
            break

          case '2/1':
            posts = slice(postsTaken, postsTaken + 2, postIds)
            row = [mkMediumPost(posts[0]), mkSmallPost(posts[1])]
            break

          case '1/2':
            posts = slice(postsTaken, postsTaken + 2, postIds)
            row = [mkSmallPost(posts[0]), mkMediumPost(posts[1])]
            break

          case '3':
            posts = slice(postsTaken, postsTaken + 1, postIds)
            row = [mkLargePost(posts[0])]
            break

          case 'd/1/1':
            posts = slice(postsTaken, postsTaken + 2, postIds)
            row = [
              mkDropIn(drop_in),
              mkSmallPost(posts[0]),
              mkSmallPost(posts[1]),
            ]
            break

          case '1/d/1':
            posts = slice(postsTaken, postsTaken + 2, postIds)
            row = [
              mkSmallPost(posts[0]),
              mkDropIn(drop_in),
              mkSmallPost(posts[1]),
            ]
            break

          case '1/1/d':
            posts = slice(postsTaken, postsTaken + 2, postIds)
            row = [
              mkSmallPost(posts[0]),
              mkSmallPost(posts[1]),
              mkDropIn(drop_in),
            ]
            break

          case '2/d':
            posts = slice(postsTaken, postsTaken + 1, postIds)
            row = [mkMediumPost(posts[0]), mkDropIn(drop_in)]
            break

          case 'd/2':
            posts = slice(postsTaken, postsTaken + 1, postIds)
            row = [mkDropIn(drop_in), mkMediumPost(posts[0])]
            break

          default:
        }

        return {
          rows: [...rows, row],
          postsTaken: postsTaken + posts.length,
        }
      },
      { rows: [], postsTaken: 0 },
      layout
    )

    const useLayout = layoutColumns === 3

    return compose(
      useLayout ? concat(layoutRows) : identity,
      splitEvery(layoutColumns),
      !useLayout ? concat(unnest(layoutRows)) : identity,
      map(mkSmallPost),
      slice(postsTaken, Infinity)
    )(postIds)
  }
)

const sLayout = createSelector(
  state => state.options,
  sIsFrontpage,
  sIsCategory,
  (options, isFrontpage, isCategory) => {
    if (isFrontpage) {
      return options.frontpage_layout
    } else if (isCategory) {
      return options.category_layout
    }
    return false
  }
)
