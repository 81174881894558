import values from 'ramda/es/values'
import { createSelector } from 'reselect'
import compose from 'ramda/es/compose'
import filter from 'ramda/es/filter'
import head from 'ramda/es/head'
import prop from 'ramda/es/prop'
import not from 'ramda/es/not'
import concat from 'ramda/es/concat'

const initialState = {}
export default (state = initialState, action) => {
  switch (action.type) {
    default:
  }
  return state
}

export const sAllCategories = createSelector(
  state => state.categories,
  state => state.categoryIds,
  (categories, categoryIds) => categoryIds.map(id => categories[id])
)

export const sHotTopics = createSelector(
  sAllCategories,
  filter(prop('hot_topic'))
)
export const sNotHotTopics = createSelector(
  sAllCategories,
  filter(compose(not, prop('hot_topic')))
)

export const sAllCategoriesHotTopicsFirst = createSelector(
  sHotTopics,
  sNotHotTopics,
  concat
)

export const sCurrentCategory = createSelector(
  state => state.location.payload,
  state => state.categories,
  ({ category }, categories) =>
    compose(head, filter(({ slug }) => slug === category), values)(categories)
)
