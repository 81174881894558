import $ from 'jquery'
import 'jquery-countdown'

$(document).ready(function() {
  dhsv_vc_new()
})

function dhsv_vc_new() {
  $('.countdown-container').each(function(index, el) {
    $(el).countdown($(el).data('date') + ':00', function(event) {
      $(this).html(
        event.strftime(
          '<div class="countdown-element"><span class="countdown-number">%D</span><span class="countdown-description">Days</span></div><div class="countdown-element"><span class="countdown-number">%H</span><span class="countdown-description">Hours</span></div><div class="countdown-element"><span class="countdown-number">%M</span><span class="countdown-description">Minutes</span></div><div class="countdown-element"><span class="countdown-number">%S</span><span class="countdown-description">Seconds</span></div>'
        )
      )
    })
  })
}
