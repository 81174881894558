import React from 'react'

class DropInTwitter extends React.Component {
  render() {
    return (
      <div className="drop-in box social twitter active">
        <div className="content">
          <div className="content_inner">
            <h2 className="title">Follow us on Twitter</h2>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/BoschSI"
              className="btn btn-primary"
            >
              Subscribe
            </a>
          </div>
        </div>
      </div>
    )
  }
}

DropInTwitter.propTypes = {}

export default DropInTwitter
