import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from '../InfiniteScroll'
import { Row } from '../FrontPage/component'

class CategoryArchive extends React.Component {
  render() {
    const { rows } = this.props
    return (
      <Fragment>
        <InfiniteScroll
          style={{ marginTop: '25px' }}
          rows={rows}
          RowComponent={Row}
        />
      </Fragment>
    )
  }
}

CategoryArchive.propTypes = {
  rows: PropTypes.array,
}

export default CategoryArchive
