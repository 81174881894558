import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_new()
})

function dhsv_vc_new() {
  $('.dhsv_vc_teaser_slider').each(function(index, el) {
    let $slider = $(el).find('.teaser-slider')
    let sliderSpeed = $slider.attr('data-speed')
    let sliderAutoplay = $slider.attr('data-autoplay')

    function startStatusBar(slider) {
      slider
        .closest('.dhsv_vc_teaser_slider')
        .find('[data-progress-bar]')
        .stop()
        .css('width', '')
        .animate(
          {
            width: '100%',
          },
          Number(sliderSpeed)
        )
    }

    $slider
      .on('init', function(event, slick) {
        if (
          slick.$slides
            .eq(0)
            .find('.teaserbox')
            .hasClass('light')
        ) {
          slick.$slider
            .closest('.dhsv_vc_teaser_slider')
            .find('.progress-line')
            .css('background-color', '#000')
        }
        startStatusBar(slick.$slider)
      })
      .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        if (
          slick.$slides
            .eq(nextSlide)
            .find('.teaserbox')
            .hasClass('light')
        ) {
          slick.$slider
            .closest('.dhsv_vc_teaser_slider')
            .find('.progress-line')
            .css('background-color', '#000')
        } else {
          slick.$slider
            .closest('.dhsv_vc_teaser_slider')
            .find('.progress-line')
            .css('background-color', '')
        }
        startStatusBar(slick.$slider)
      })

    $slider.slick({
      dots: true,
      arrows: true,
      infinite: true,
      autoplay: sliderAutoplay,
      autoplaySpeed: Number(sliderSpeed),
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
    })
  })
}
