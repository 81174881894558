import React from 'react'

class DropInNewsletter extends React.Component {
  render() {
    return (
      <div className="drop-in box social newsletter active">
        <div className="content">
          <div className="content_inner">
            <h2 className="title">Subscribe to our Newsletter</h2>
            <form className="">
              <input type="email" placeholder="Email" />
              <button type="submit" className="btn btn-primary">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

DropInNewsletter.propTypes = {}

export default DropInNewsletter
