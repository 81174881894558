import React from 'react'
import PropTypes from 'prop-types'
import { postShape } from '../../shared/shapes'

const gridClasses = {
  xsmall: 'col-sm-3',
  small: 'col-xs-12 col-md-6 col-lg-4',
  medium: 'col-xs-12 col-md-6 col-lg-8',
  large: 'col-xs-12 col-md-6 col-lg-12',
}

const thumbNames = {
  xsmall: 'box_small',
  small: 'box_small',
  medium: 'box_medium',
  large: 'box_large',
}

class Article extends React.Component {
  render() {
    const {
      post: {
        id,
        title: { rendered: title },
        date_display,
        post_thumbnail,
        link,
        post_style,
        primary_author: { first_name, last_name },
        color,
        dont_crop_featured_image,
        featured,
        featured_source,
        excerpt,
        primary_category,
        youtube_id,
        reading_time,
        post_box_image_horizontal_alignment,
      },
      size,
    } = this.props

    const sizeClass = gridClasses[size]
    const featuredClass = featured ? 'featured' : ''
    const videoClass = youtube_id ? 'video' : ''
    const codeClass = post_style === 'developer' ? 'code' : ''
    const dontCropClass = dont_crop_featured_image
      ? 'dont-crop-featured-image'
      : ''

    const thumbUrl = dont_crop_featured_image
      ? post_thumbnail['post_variable_height_medium']
      : post_thumbnail[thumbNames[size]]

    return (
      <div className={sizeClass} data-post-id={id}>
        <a href={link}>
          <div
            className={`box ${color} ${featuredClass} ${videoClass} ${codeClass} ${dontCropClass}`}
          >
            <div className="image">
              <img
                title={title}
                alt={title}
                src={thumbUrl}
                style={{
                  objectPosition: post_box_image_horizontal_alignment
                    ? post_box_image_horizontal_alignment
                    : 'center',
                }}
              />
              {youtube_id ? (
                <div className="playbutton">
                  <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 100">
                    <circle className="circle" cx="50" cy="50" r="47.5" />
                    <path
                      className="play"
                      d="M42,38.7l19.2,11.9L42,61.6V38.7 M37,29.8v40.5l34-19.4L37,29.8L37,29.8z"
                    />
                  </svg>
                </div>
              ) : null}
            </div>
            <div className="content">
              <div className="content_inner">
                {primary_category ? (
                  <ul className="post-categories">
                    <li>{primary_category['name']}</li>
                  </ul>
                ) : null}
                <h2
                  className="title"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                {excerpt ? (
                  <div className="excerpt">
                    <div>{excerpt}</div>
                  </div>
                ) : null}
                <span className="meta">
                  <ul>
                    <li className="date">
                      <i className="boschicons-date" /> {date_display}
                    </li>
                    {first_name && last_name ? (
                      <li className="author">
                        <i className="boschicons-author" />
                        {` ${first_name} ${last_name}`}
                      </li>
                    ) : null}
                    <li className="reading-time">
                      <i className="boschicons-reading-time" />
                      {reading_time}
                    </li>
                    {featured_source && post_style !== 'developer' ? (
                      <li className="featured-source">
                        <i className="boschicons-icon_image" /> &copy;{
                          featured_source
                        }
                      </li>
                    ) : null}
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </a>
      </div>
    )
  }
}

Article.propTypes = {
  post: postShape,
  size: PropTypes.string.isRequired,
}

Article.defaultProps = {
  size: 'small',
}

export default Article
