import q from 'query-string'
import pick from 'ramda/es/pick'
import toPairs from 'ramda/es/toPairs'
import { utmParams } from './shared'

export const storeParams = () => {
  const params = q.parse(window.location.search)
  const paramsToStore = pick(utmParams, params)

  toPairs(paramsToStore).forEach(([k, v]) =>
    window.sessionStorage.setItem(k, v)
  )
}
