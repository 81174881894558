import React from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'

class AccordionBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0,
    }
    this.toggle = this.toggle.bind(this)
  }

  componentDidMount() {
    scroll(0, 0)
    const { data } = this.props
    var hash = window.location.hash.substring(1)
    if (hash == 'faq-' + data.anchor) {
      // eslint-disable-next-line no-console
      console.log('YEAH the same')
      var target = $(window.location.hash)
      if (target.length) {
        $('html, body').animate(
          {
            scrollTop: target.offset().top - 100,
          },
          3000,
          function() {
            // Callback after animation
            // Must change focus!
            var $target = $(target)
            $target.focus()
            if ($target.is(':focus')) {
              // Checking if the target was focused
              return false
            } else {
              $target.attr('tabindex', '-1') // Adding tabindex for elements not focusable
              $target.focus() // Set focus again
            }
          }
        )
        const { height } = this.state
        this.setState({
          height: height === 0 ? 'auto' : 0,
        })
      }
    }
  }

  toggle() {
    const { height } = this.state

    this.setState({
      height: height === 0 ? 'auto' : 0,
    })
  }

  render() {
    const { height } = this.state
    const { data } = this.props
    return (
      <div
        id={`faq-${data.anchor}`}
        className={`post-item post ${height !== 0 ? ' open' : ''}`}
      >
        <div className="text">
          <div className="acc-head" onClick={this.toggle}>
            <p className="h4 subheadline">{data.title}</p>
            <div className="more-info" />
          </div>
          <AnimateHeight
            duration={500}
            height={height} // see props documentation bellow
            className="acc-body"
          >
            <p dangerouslySetInnerHTML={{ __html: data.content }} />
          </AnimateHeight>
        </div>
      </div>
    )
  }
}

AccordionBox.propTypes = {
  data: PropTypes.object,
}

export default AccordionBox
