import $ from 'jquery'
import 'slick-carousel'
import 'jquery-match-height'

$(document).ready(function() {
  dhsv_vc_partners_slider()
})

function dhsv_vc_partners_slider() {
  $('.dhsv_vc_partners_slider').each(function(index, el) {
    let $slider = $(el).find('.partners_slider')
    let sliderSpeed = $slider.attr('data-speed')
    let sliderAutoplay = $slider.attr('data-autoplay')
    let sliderShow = $slider.attr('data-slide-show')

    function setSlidesClass(slick, currentSlide) {
      slick.$slider
        .find('.slick-slide')
        .removeClass('second-size second-prev second-next current-slide')

      slick.$slides
        .eq(currentSlide)
        .addClass('current-slide')
        .prev()
        .addClass('second-size second-prev')
      slick.$slides
        .eq(currentSlide)
        .next()
        .addClass('second-size second-next')
    }

    function setSlidesClassInit(slick, currentSlide) {
      slick.$slides
        .eq(currentSlide)
        .addClass('current-slide')
        .prev()
        .addClass('second-size second-prev')
      slick.$slides
        .eq(currentSlide)
        .next()
        .addClass('second-size second-next')
    }

    $slider
      .on('init', function(event, slick) {
        setSlidesClassInit(slick, 0)
      })
      .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        setSlidesClass(slick, nextSlide)
      })

    $slider.slick({
      dots: false,
      arrows: false,
      infinite: true,
      centerMode: true,
      centerPadding: 0,
      focusOnSelect: true,
      swipe: false,
      autoplay: sliderAutoplay,
      autoplaySpeed: Number(sliderSpeed),
      slidesToShow: Number(sliderShow),
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
    })
  })
}
