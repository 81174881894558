import { startAppend } from './appendParams'
import { storeParams } from './storeParams'

const startUtmForward = () => {
  // only activate utm forwarding if session storage is available
  if (!(window.sessionStorage && window.sessionStorage.setItem)) {
    return
  }

  storeParams()
  startAppend()
}

startUtmForward()
