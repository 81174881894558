import map from 'ramda/es/map'
import prop from 'ramda/es/prop'
import compose from 'ramda/es/compose'
import toPairs from 'ramda/es/toPairs'
import sortBy from 'ramda/es/sortBy'
import chain from 'ramda/es/chain'
import { createSelector } from 'reselect'
import { QUERY_POSTS_SUCCESS, LOAD_INITIAL_POSTS } from './posts'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INITIAL_POSTS:
    case QUERY_POSTS_SUCCESS: // eslint-disable-line
      const { payload: { page = 1 } } = action.dispatchLocation
      const routeKey = getRouteKey(action.dispatchLocation)

      // die von buble-loader bereitgestelle buble Version kommt nicht
      // mit object spreads in Verbindung mit dynamischen keys (...{ [var]: "value" })  zurecht
      return Object.assign({}, state, {
        [routeKey]: Object.assign({}, state[routeKey] ? state[routeKey] : {}, {
          [page]: map(prop('id'), action.json.result),
        }),
      })

    default:
  }
  return state
}

// calculate a cache key for the current route.
// this has to contain category and search parameters where pagination happens under
export const getRouteKey = ({ type, payload: { search, category } }) => {
  const route = type.includes('_PAGINATED') ? type : type + '_PAGINATED'

  const categorySuffix = category ? `_${category}` : ''

  const searchSuffix = search ? `_${search}` : ''

  return `${route}${categorySuffix}${searchSuffix}`
}

export const sRouteKey = createSelector(state => state.location, getRouteKey)

export const sCurrentRouteLoaded = createSelector(
  sRouteKey,
  state => state.location.payload,
  state => state.postsByRoute,
  (key, { page = 1 }, postsByRoute) =>
    postsByRoute[key] && postsByRoute[key][page]
)

export const sPostIdsForCurrentRoute = createSelector(
  sRouteKey,
  state => state.postsByRoute,
  (key, postsByRoute) => {
    return postsByRoute[key]
      ? compose(
          chain(prop(1)),
          sortBy(compose(x => parseInt(x, 10), prop(0))),
          toPairs
        )(postsByRoute[key])
      : []
  }
)
