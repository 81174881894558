import $ from 'jquery'
import * as bodymovin from 'bodymovin'

function titleAnimation() {
  let animations = new Array()
  var animLength = 90
  var segment = [0, animLength]
  var startFrame = 0
  var endFrame = animLength

  $('.title-bg[data-animation]').each(function(index, el) {
    animations.push(
      bodymovin.loadAnimation({
        container: el,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: $(el).data('animation'),
        rendererSettings: {
          preserveAspectRatio: 'xMaxYMin meet',
        },
      })
    )
  })

  animations.forEach(function(el) {
    el.addEventListener('complete', animationFinished)
  })

  function createAnimSegment() {
    animations.forEach(function(el) {
      startFrame = endFrame
      endFrame = animLength - window.pageYOffset * 0.1

      segment[0] = startFrame
      segment[1] = endFrame
      el.playSegments(segment, false)
    })
  }

  function animationFinished() {
    createAnimSegment()
  }

  $(window).load(function() {
    animations.forEach(function(el) {
      el.setSpeed(5)
      segment[0] = startFrame
      segment[1] = endFrame
      el.playSegments(segment, false)
    })
  })
}

$(document).on('load', titleAnimation())
