import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function() {
  dhsv_vc_slider()
})

function dhsv_vc_slider() {
  $('.dhsv_vc_slider').each(function(index, el) {
    let $slider = $(el).find('.animated_slider')
    let sliderSpeed = $slider.attr('data-speed')
    let sliderAutoplay = $slider.attr('data-autoplay')

    function startStatusBar(slider) {
      slider
        .closest('.dhsv_vc_slider')
        .find('[data-progress-bar]')
        .stop()
        .css('width', '')
        .animate(
          {
            width: '100%',
          },
          Number(sliderSpeed)
        )
    }

    $slider
      .on('init', function(event, slick) {
        startStatusBar(slick.$slider)
      })
      .on('beforeChange', function(event, slick) {
        startStatusBar(slick.$slider)
      })

    $slider.slick({
      dots: true,
      arrows: true,
      infinite: true,
      autoplay: sliderAutoplay,
      autoplaySpeed: Number(sliderSpeed),
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
      adaptiveHeight: true,
    })

    $slider.find('.slick-cloned [data-fancybox]').removeAttr('data-fancybox')
  })
}
