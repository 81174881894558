import { QUERY_POSTS_SUCCESS, LOAD_INITIAL_POSTS } from './posts'
import { stripTrailingSlashes } from '../../util/string'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INITIAL_POSTS:
    case QUERY_POSTS_SUCCESS:
      return {
        ...state,
        [stripTrailingSlashes(action.dispatchLocation.pathname)]: true,
      }
    default:
  }
  return state
}
