import {
  ACTIVATE as ACTIVATE_SEARCH,
  DEACTIVATE as DEACTIVATE_SEARCH,
} from './search'
import {
  CATEGORY_ARCHIVE,
  CATEGORY_ARCHIVE_PAGINATED,
  FRONTPAGE,
  FRONTPAGE_PAGINATED,
  SEARCH,
  SEARCH_PAGINATED,
} from './routes'

const initialState = {
  activesearch: false,

  category: false,
  home: false,
  'front-page-data': false,
  search: false,
}

export default function bodyClassesReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVATE_SEARCH:
      return {
        ...state,
        activesearch: true,
      }

    case DEACTIVATE_SEARCH:
      return {
        ...state,
        activesearch: false,
      }

    case CATEGORY_ARCHIVE:
    case CATEGORY_ARCHIVE_PAGINATED:
      return {
        ...state,
        category: true,
      }

    case FRONTPAGE:
    case FRONTPAGE_PAGINATED:
      return {
        ...state,
        home: true,
        'front-page-data': true,
      }

    case SEARCH:
    case SEARCH_PAGINATED:
      return {
        ...state,
        search: true,
      }

    default:
  }

  if (action.type && action.payload && action.meta) {
    return {
      ...state,
      category: false,
      home: false,
      'front-page-data': false,
      search: false,
    }
  }

  return state
}
