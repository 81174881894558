import { createSelector } from 'reselect'
import { stripTrailingSlashes } from '../../util/string'

export const POST = 'bos001/routes/POST'

export const FRONTPAGE = 'bos001/routes/FRONTPAGE'
export const FRONTPAGE_PAGINATED = 'bos001/routes/FRONTPAGE_PAGINATED'

export const SEARCH = 'bos001/routes/SEARCH'
export const SEARCH_PAGINATED = 'bos001/routes/SEARCH_PAGINATED'

export const CATEGORY_ARCHIVE = 'bos001/routes/CATEGORY_ARCHIVE'
export const CATEGORY_ARCHIVE_PAGINATED =
  'bos001/routes/CATEGORY_ARCHIVE_PAGINATED'

export const AUTHORS = 'bos001/routes/AUTHORS'
export const AUTHORS_PAGINATED = 'bos001/routes/AUTHORS_PAGINATED'

export const sCurrentPathLoaded = createSelector(
  state => state.location.pathname,
  state => state.pathLoaded,
  (pathname, pathLoaded) => !!pathLoaded[stripTrailingSlashes(pathname)]
)

export const sIsInitialPath = ({ location: { prev: { pathname, type } } }) =>
  pathname === '' && type === ''
