import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Component from './component'
import PropTypes from 'prop-types'

const WithData = connect(
  (state, props) => ({
    post: props.recommendedPost ? props.post : state.posts[props.post_id],
    browser: state.browser,
    recommendedPost: props.recommendedPost,
  }),
  dispatch => bindActionCreators({}, dispatch)
)(Component)

WithData.propTypes = {
  post_id: PropTypes.number,
  recommendedPost: PropTypes.bool,
}

export default WithData
