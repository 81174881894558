import { createSelector } from 'reselect'
import { getRouteKey, sRouteKey } from './postsByRoute'
import { QUERY_POSTS_SUCCESS, LOAD_INITIAL_POSTS } from './posts'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INITIAL_POSTS:
    case QUERY_POSTS_SUCCESS:
      return {
        ...state,
        [getRouteKey(action.dispatchLocation)]: parseInt(
          action.json.headers['X-WP-TotalPages'],
          10
        ),
      }
    default:
  }
  return state
}

export const sTotalPages = createSelector(
  sRouteKey,
  state => state.totalPages,
  (key, totalPages) => totalPages[key] || 0
)
