import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

class FactSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var settings = {
      dots: true,
    }

    return (
      <div className="factslider">
        <Slider {...settings}>
          {this.props.slides.map((slide, i) => (
            <div className="item" key={i}>
              <p>{slide.item}</p>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

FactSlider.propTypes = {
  slides: PropTypes.array,
}

export default FactSlider
