import React from 'react'
import PropTypes from 'prop-types'
import RecommendedArticle from '../RecommendedArticles/RecommendedArticle'
import { postShape } from '../../shared/shapes'
import './styles.scss'

const EmptySearch = ({ recentPosts, isLessThanXl }) => (
  <div className={'empty-search'}>
    <div className={'notice'}>
      The following articles may be of interest to you...
    </div>
    <div className={'row'}>
      {recentPosts.map((p, k) => (
        <RecommendedArticle key={k} post={p} isLessThanXl={isLessThanXl} />
      ))}
    </div>
  </div>
)

export default EmptySearch

EmptySearch.propTypes = {
  recentPosts: PropTypes.arrayOf(postShape),
  isLessThanXl: PropTypes.bool,
}
