import React from 'react'
import PropTypes from 'prop-types'
import {
  AutoSizer,
  InfiniteLoader,
  List,
  WindowScroller,
} from 'react-virtualized'
import LoadingIndicator from '../LoadingIndicator'

class InfiniteScroll extends React.Component {
  render() {
    const {
      rows,
      hasNextPage,
      isNextPageLoading,
      loadNextPage,
      rowHeight,
      style,
      threshold,
      containerStyle,
    } = this.props

    const rowCount = hasNextPage ? rows.length + 1 : rows.length

    const loadMoreRows = isNextPageLoading ? () => {} : loadNextPage

    const isRowLoaded = ({ index }) => !hasNextPage || index < rows.length

    const rowRenderer = ({ index, key, style }) => {
      const { RowComponent } = this.props

      const finalStyle = { ...style, width: '100%', marginLeft: 0 }

      if (!isRowLoaded({ index })) {
        return <LoadingIndicator key={key} style={finalStyle} />
      }
      const row = rows[index]

      return <RowComponent key={key} style={finalStyle} row={row} />
    }

    if (!rows) return null
    return (
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={rowCount}
        threshold={threshold}
      >
        {({ onRowsRendered, registerChild }) => (
          <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    autoHeight
                    style={{ ...style, left: '-15px' }}
                    height={height}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    scrollTop={scrollTop}
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    rowRenderer={rowRenderer}
                    rowCount={rowCount}
                    width={width + 30}
                    rowHeight={rowHeight}
                    containerStyle={containerStyle}
                  />
                )}
              </AutoSizer>
            )}
          </WindowScroller>
        )}
      </InfiniteLoader>
    )
  }
}

InfiniteScroll.propTypes = {
  rows: PropTypes.array,
  hasNextPage: PropTypes.bool,
  isNextPageLoading: PropTypes.bool,
  loadNextPage: PropTypes.func,
  RowComponent: PropTypes.any,
  rowHeight: PropTypes.number,
  style: PropTypes.object,
  threshold: PropTypes.number,
  containerStyle: PropTypes.object,
}

InfiniteScroll.defaultProps = {
  threshold: 3,
  rowHeight: 552,
}

export default InfiniteScroll
