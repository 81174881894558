import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Link from 'redux-first-router-link'
import InfiniteScroll from '../InfiniteScroll'
import Article from '../Article'
import EmptySearch from '../EmptySearch'
import { FRONTPAGE } from '../../redux/modules/routes'

import './styles.scss'

class Row extends React.Component {
  render() {
    const { row, style } = this.props
    return (
      <div className="row flex-row" style={style}>
        {row.map(postId => <Article post_id={postId} key={postId} />)}
      </div>
    )
  }
}

Row.propTypes = {
  row: PropTypes.array,
  style: PropTypes.object,
}

const InfoBar = ({ left, right }) => (
  <div className={'info-bar'}>
    <div className={'left'}>{left}</div>
    <div className={'right'}>{right}</div>
  </div>
)

InfoBar.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
}

class SearchArchive extends React.Component {
  render() {
    const { rows, searchString, isEmptySearchResult } = this.props

    return (
      <div className={'search-archive'}>
        {isEmptySearchResult ? (
          <InfoBar
            left={
              <Fragment>
                No results were found for{' '}
                <span className={'search-term'}>
                  &raquo;{searchString}&laquo;
                </span>
              </Fragment>
            }
            right={<span className={'shrug'}>¯\_(ツ)_/¯</span>}
          />
        ) : (
          <InfoBar
            left={
              <Fragment>
                Your search for{' '}
                <span className={'search-term'}>
                  &raquo;{searchString}&laquo;
                </span>
              </Fragment>
            }
            right={
              <Fragment>
                {' '}
                <Link to={{ type: FRONTPAGE }}>
                  <i className="boschicons-close" />
                </Link>
              </Fragment>
            }
          />
        )}
        {isEmptySearchResult ? (
          <EmptySearch />
        ) : (
          <InfiniteScroll
            style={{ marginTop: '25px' }}
            rows={rows}
            RowComponent={Row}
          />
        )}
      </div>
    )
  }
}

SearchArchive.propTypes = {
  rows: PropTypes.array,
  searchString: PropTypes.string,
  isEmptySearchResult: PropTypes.bool,
}

export default SearchArchive
