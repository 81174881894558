import { QUERY_POSTS, QUERY_POSTS_FAILURE, QUERY_POSTS_SUCCESS } from './posts'

const initialState = {
  posts: false,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case QUERY_POSTS:
      return {
        ...state,
        posts: true,
      }
    case QUERY_POSTS_SUCCESS:
    case QUERY_POSTS_FAILURE:
      return {
        ...state,
        posts: false,
      }
    default:
  }
  return state
}
