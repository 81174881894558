import $ from 'jquery'
import { gtmTrackEvent, isDownloadLink } from '../../util/tracking'

// track navigaton
$(document).on('click', '#menu-main-menu .menu-item a', e => {
  const $a = $(e.currentTarget)
  const eventLabel = $a.text()

  gtmTrackEvent({
    event: 'gaEvent',
    eventCategory: 'Navigation',
    eventAction: 'Header',
    eventLabel,
    eventValue: undefined,
  })
})

$(document).on('click', '#menu-top-menu .menu-item a', e => {
  const $a = $(e.currentTarget)
  const eventLabel = $a.text()

  gtmTrackEvent({
    event: 'gaEvent',
    eventCategory: 'Navigation',
    eventAction: 'Header',
    eventLabel,
    eventValue: undefined,
  })
})

$(document).on('click', '.footer ul.footer-links li a', e => {
  const $a = $(e.currentTarget)
  const eventLabel = $a.text()

  gtmTrackEvent({
    event: 'gaEvent',
    eventCategory: 'Navigation',
    eventAction: 'Footer',
    eventLabel,
    eventValue: undefined,
  })
})

$(document).on('click', '.dhsv_vc_faq .post-item:not(.open)', e => {
  const $postitem = $(e.currentTarget)
  const eventLabel = $postitem.find('.acc-head p.h4').text()

  gtmTrackEvent({
    event: 'gaEvent',
    eventCategory: 'Navigation',
    eventAction: 'Unfold',
    eventLabel,
    eventValue: undefined,
  })
})

// track e-mail links
$(document).on('click', '.page page-id-37766 a[href^="mailto:"]', () => {
  const eventAction = $('head title').text()

  gtmTrackEvent({
    event: 'gaEvent',
    eventCategory: 'Customer Relations',
    eventAction,
    eventLabel: 'Contact Us',
    eventValue: undefined,
  })
})

$(document).on('click', '.page-id-38345 a[href^="mailto:"]', () => {
  const eventAction = $('head title').text()

  gtmTrackEvent({
    event: 'gaEvent',
    eventCategory: 'Customer Relations',
    eventAction,
    eventLabel: 'Contact Us',
    eventValue: undefined,
  })
})

// track clicks on download links and arbitrary outbound links
$(document).on('click', 'a:not(.social-outbound)', e => {
  const $a = $(e.currentTarget)
  const href = $a.attr('href')
  const text = $a.text()

  if (isDownloadLink(href)) {
    gtmTrackEvent({
      event: 'gaEvent',
      eventCategory: 'Downloads',
      eventAction: text,
      eventLabel: href,
      eventValue: undefined,
    })
    return
  }

  const isOutbound = !new RegExp(
    `^(https?:)?\\/\\/${location.hostname}(:\\d+)?\\/`,
    'i'
  ).test(href)

  if (!isOutbound) {
    return
  }

  gtmTrackEvent({
    event: 'gaEvent',
    eventCategory: 'Outbound Links',
    eventAction: text,
    eventLabel: href,
    eventValue: undefined,
  })
})

// track clicks on social outbound links
$(document).on('click', 'a.social-outbound', e => {
  const $a = $(e.currentTarget)
  const network = $a.attr('data-social')

  gtmTrackEvent({
    event: 'gaEvent',
    eventCategory: 'Social Outbounds',
    eventAction: network,
    eventLabel: 'Company Profile',
    eventValue: undefined,
  })
})

// track enhanced eCommerce-Promations
$(document).on('click', '.dhsv_vc_teaserbox a', () => {
  const name = $('.dhsv_vc_teaserbox .teaserbox__content h2')
  gtmTrackEvent({
    event: 'EECpromotionClick',
    ecommerce: {
      promoClick: {
        promotions: [
          {
            name: name,
          },
        ],
      },
    },
  })
})

const trackPromotionImpressions = () => {
  if (typeof IntersectionObserver == 'undefined') {
    return
  }

  const observer = new IntersectionObserver(
    entries => {
      const appearedEntries = entries.filter(
        ({ isIntersecting }) => isIntersecting
      )

      if (!appearedEntries.length) {
        return
      }

      const promotions = appearedEntries.map(({ target: domElement }) => {
        return {
          name: $(domElement)
            .find('.teaserbox__content h4')
            .text(),
        }
      })

      gtmTrackEvent({
        event: 'EECpromotionImpression',
        ecommerce: {
          promoView: {
            promotions,
          },
        },
      })
    },
    {
      threshold: 0.5,
    }
  )

  const observeTargets = () => {
    const targets = document.querySelectorAll('.dhsv_vc_teaserbox')

    targets.forEach(t => observer.observe(t))
  }

  setInterval(observeTargets, 100)
}

trackPromotionImpressions()
