import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Component from './component'

import {
  sSuggestions,
  sHaveSuggestions,
} from '../../redux/modules/searchSuggestions'
import { sCurrentSelection } from '../../redux/modules/searchKeyControl'
import { sIsPost } from '../../redux/modules/posts'

export default connect(
  state => ({
    suggestions: sSuggestions(state),
    currentSelection: sCurrentSelection(state),
    haveSuggestions: sHaveSuggestions(state),
    isPost: sIsPost(state),
  }),
  dispatch => bindActionCreators({}, dispatch)
)(Component)
