import { combineReducers } from 'redux'
import router from './router'
import browser from './browser'
import categories from './categories'
import categoryIds from './categoryIds'
import bodyClasses from './bodyClasses'
import initialPage from './initialPage'
import loading from './loading'
import options from './options'
import pathLoaded from './pathLoaded'
import posts from './posts'
import postsByPath from './postsByPath'
import postsByRoute from './postsByRoute'
import search from './search'
import tags from './tags'
import totalPages from './totalPages'
import totalPosts from './totalPosts'
import recommendedPosts from './recommendedPosts'
import recentPostIdsByTermId from './recentPostIdsByTermId'
import searchKeyControl from './searchKeyControl'
import searchSuggestions from './searchSuggestions'
import conferenceProgram from './conferenceProgram'

const appReducer = combineReducers({
  browser,
  categories,
  categoryIds,
  conferenceProgram,
  initialPage,
  location: router,
  bodyClasses,
  loading,
  options,
  pathLoaded,
  posts,
  postsByPath,
  postsByRoute,
  search,
  tags,
  totalPages,
  totalPosts,
  recommendedPosts,
  recentPostIdsByTermId,
  searchKeyControl,
  searchSuggestions,
})

export default appReducer
