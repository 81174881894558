import React from 'react'
import PropTypes from 'prop-types'

class SocialLink extends React.Component {
  render() {
    const { name, url } = this.props
    if (!name || !url) return null
    return (
      <a target="_blank" href={url}>
        <i className={`boschicons-${name}`} aria-hidden="true" />
      </a>
    )
  }
}

SocialLink.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
}

class Author extends React.Component {
  render() {
    const {
      post: {
        first_name,
        last_name,
        link,
        avatar,
        email,
        twitter,
        google_plus,
        linkedin,
        xing,
        website,
      },
    } = this.props

    const name = `${first_name} ${last_name}`

    return (
      <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
        <div className="author-item">
          <a href={link}>
            {avatar && (
              <img src={avatar.thumbnail} alt={name} width="200" height="200" />
            )}
            <span className="name">{name}</span>
          </a>
          <span className="social">
            <SocialLink name="mail" url={`mailto:${email}`} />
            <SocialLink name="twitter" url={twitter} />
            <SocialLink name="google-plus" url={google_plus} />
            <SocialLink name="linkedIn" url={linkedin} />
            <SocialLink name="xing" url={xing} />
            <SocialLink name="author" url={website} />
          </span>
        </div>
      </div>
    )
  }
}

Author.propTypes = {
  post: PropTypes.object,
}

export default Author
