import React from 'react'
import PropTypes from 'prop-types'

class LoadingIndicator extends React.Component {
  render() {
    const { style } = this.props
    const rowStyle = {
      textAlign: 'center',
    }
    return (
      <div className="row loading-indicator" style={{ ...rowStyle, ...style }}>
        <div className="col-sm-12">
          <span>
            Loading
            <ul className="loadpoints">
              <li>.</li>
              <li>.</li>
              <li>.</li>
            </ul>
          </span>
        </div>
      </div>
    )
  }
}

LoadingIndicator.propTypes = {
  style: PropTypes.object,
}

export default LoadingIndicator
