import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Component from './component'
import { queryPosts, sHasNextPage } from '../../redux/modules/posts'
import { sTotalPosts } from '../../redux/modules/totalPosts'

const WithData = connect(
  state => ({
    hasNextPage: sHasNextPage(state),
    isNextPageLoading: state.loading.posts,
  }),
  dispatch =>
    bindActionCreators(
      {
        loadNextPage,
      },
      dispatch
    )
)(Component)

export default WithData

const loadNextPage = () => (dispatch, getState) => {
  const state = getState()
  const { location: { payload, type: oldType } } = state
  const { page = 1 } = payload
  const totalPosts = sTotalPosts(state)
  const type = payload.page ? oldType : oldType + '_PAGINATED'

  if (!totalPosts) {
    return
  }

  dispatch({
    type,
    payload: {
      ...payload,
      page: page + 1,
    },
  })
  return dispatch(queryPosts(payload))
}
