import $ from 'jquery'

export default function shareSelection() {
  $(
    '.content .singleblog-row,.content .singlecontent,.content .more_information'
  ).on('mouseup', function(e) {
    if (
      $(e.target).closest(
        '.singleblog-row .meta, .singleblog-row .post-categories li, .singleblog-row .post-categories a, .img-wrapper img, .img-wrapper .source, .story_stage .source'
      ).length > 0
    ) {
      return
    }

    window.setTimeout(function() {
      const selected = window.getSelection().toString()

      if (typeof selected !== 'string' || selected.trim().length <= 0) {
        return
      }
      const url = window.location.href
      const tweet = encodeURIComponent(`${selected}
${url} | via @BoschSI`)
      const twitterUrl = `https://twitter.com/home?status=${tweet}`

      const windowParams =
        'width=566,height=592,location=yes,directories=no,channelmode=no,menubar=no,resizable=no,scrollbars=no,status=no,toolbar=no'

      const html = `<span class="share-selection">
                <a class="item twitter" target="_blank" href="${twitterUrl}" onclick="window.open('${twitterUrl}', 'tweetwindow', '${windowParams}');return false">
                  <i class="boschicons-twitter"></i>
                </a>
               </span>`

      const $shareSelection = $('.share-selection')

      if ($shareSelection.length) {
        $shareSelection.replaceWith(html)
      } else {
        $('body').append(html)
      }

      $('.share-selection').css({
        left: e.pageX,
        top: e.pageY,
      })
    }, 0)
  })

  $(document).on('click', ':not(.share-selection)', function() {
    $('body')
      .find('.share-selection')
      .remove()
  })
}
