import React from 'react'
import PropTypes from 'prop-types'
import AccordionBox from '../../scripts/components/AccordionBox'

class faq extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      sourceData: this.props.posts,
    }
    this.handleChange = this.handleChange.bind(this)
    this.filterList = this.filterList.bind(this)
  }

  handleChange() {
    this.setState({
      sourceData: this.props.posts,
    })
  }
  filterList(e) {
    const updatedList = this.props.posts.filter(item => {
      return (
        JSON.stringify(item)
          .toLowerCase()
          .search(e.target.value.toLowerCase()) !== -1
      )
    })
    this.setState({ sourceData: updatedList })
  }
  render() {
    const { terms, tags } = this.props
    const searchBox = (
      <div className="form">
        <input
          placeholder="Search for..."
          onClick={this.filterList}
          onChange={(this.handleChange, this.filterList)}
        />
        <div className="search-button" />
      </div>
    )

    return (
      <div className="dhsv_vc_faq">
        {searchBox}
        {this.state.sourceData.length == 0
          ? 'Sorry, no results were found.'
          : ''}
        <div className="search-button" />
        {tags.map((tag, i) => (
          <div className={`tag ${tag.value}`} key={i}>
            {terms.map((term, i) => {
              const items = this.state.sourceData.filter(post => {
                return (
                  term.label == post.termlabel && tag.value == post.tagvalue
                )
              })
              return items.length > 0 ? (
                <div className="term-container" key={i}>
                  <h2>{term.label}</h2>
                  {items.map((post, i) => (
                    <div className="teaser" key={i}>
                      <AccordionBox data={post} />
                    </div>
                  ))}
                </div>
              ) : null
            })}
          </div>
        ))}
      </div>
    )
  }
}

faq.propTypes = {
  posts: PropTypes.array.isRequired,
  terms: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
}

export default faq
