import $ from 'jquery'
import 'isotope-layout/dist/isotope.pkgd.min'

$(window).load(function() {
  dhsv_vc_updates()
})

function dhsv_vc_updates() {
  $('.updates-container').each(function(index, el) {
    $(el).isotope({
      itemSelector: '.update',
      horizontalOrder: true,
      masonry: {
        columnWidth: '.grid-sizer',
        gutter: '.gutter-sizer',
      },
    })
  })

  $('.fancybox-open').on('click', function(e) {
    e.preventDefault()
    let fancyBox = $(this).data('fancy')
    $('[data-fancybox="' + fancyBox + '"]')
      .eq(0)
      .trigger('click')
  })
}
