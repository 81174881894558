import { connect } from 'react-redux'
import Component from './component'
import take from 'ramda/es/take'
import { createSelector } from 'reselect'
import { sAllRecentPostsByDate } from '../../redux/modules/posts'
import { sIsLessThanXl } from '../../redux/modules/browser'

const WithData = connect(state => ({
  recentPosts: sEightRecentPostsByDate(state),
  isLessThanXl: sIsLessThanXl(state),
}))(Component)

export default WithData

const sEightRecentPostsByDate = createSelector(
  sAllRecentPostsByDate,
  sIsLessThanXl,
  (ps, sIsLessThanXl) => take(sIsLessThanXl ? 6 : 8)(ps)
)
