export const utmParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'std1',
  'std2',
  'std3',
  'std4',
]
