import map from 'ramda/es/map'
import prop from 'ramda/es/prop'
import { QUERY_POSTS_SUCCESS, LOAD_INITIAL_POSTS } from './posts'
import { stripTrailingSlashes } from '../../util/string'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INITIAL_POSTS:
    case QUERY_POSTS_SUCCESS:
      return Object.assign({}, state, {
        [stripTrailingSlashes(action.dispatchLocation.pathname)]: map(
          prop('id'),
          action.json.result
        ),
      })
    default:
  }
  return state
}
