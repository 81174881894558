export default function callAPIMiddleware({ dispatch, getState }) {
  return function curryCallAPI(next) {
    return function doCallAPI(action) {
      const {
        types,
        callAPI,
        shouldCallAPI = function() {
          return true
        },
        payload = {},
      } = action

      if (!types) {
        // Normal action: pass it on
        return next(action)
      }

      if (
        !Array.isArray(types) ||
        types.length !== 3 ||
        !types.every(type => typeof type === 'string')
      ) {
        throw new Error('Expected an array of three string types.')
      }

      if (typeof callAPI !== 'function') {
        throw new Error('Expected callAPI to be a function.')
      }

      const state = getState()

      if (!shouldCallAPI(state)) {
        return false
      }

      const [requestType, successType, failureType] = types

      dispatch(
        Object.assign({}, payload, {
          type: requestType,
        })
      )

      return callAPI({ dispatch, getState })
        .then(response => {
          if (!response.ok) throw new Error(response.statusText)

          if (response.status !== 204) {
            return response.json().then(json => ({ response, json }))
          }

          return { response, json: false }
        })
        .then(({ response, json }) => {
          dispatch(
            Object.assign({}, payload, {
              response: response,
              json: json,
              type: successType,
              dispatchLocation: state.location,
            })
          )
          return json
        })
        .catch(error => {
          if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line
            console.error(error)
          }
          dispatch(
            Object.assign({}, payload, {
              error: error,
              type: failureType,
            })
          )
        })
    }
  }
}
