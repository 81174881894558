import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function() {
  dhsv_vc_timetable()
})

function dhsv_vc_timetable() {
  $('.dhsv_vc_timetable').each(function(index, el) {
    $(el)
      .on('mouseenter', '.timetable-item', function() {
        let time = $(this).data('time')
        let line = $(this)
          .closest('.timetable-lines')
          .find('[data-timeline="' + time + '"]')
        line.addClass('active')
      })
      .on('mouseleave', '.timetable-item', function() {
        $(this)
          .closest('.timetable-lines')
          .find('[data-timeline]')
          .removeClass('active')
      })

    let sliderArgs = {
      dots: false,
      arrows: true,
      infinite: true,
      swipe: true,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 2,
    }

    let $slider = $(el).find('.timetable-grid')

    function sliderInit() {
      if ($(window).width() > 768) {
        if ($slider.hasClass('slick-initialized')) {
          $slider.slick('unslick')
        }
        return
      }

      if (!$slider.hasClass('slick-initialized')) {
        return $slider.slick(sliderArgs)
      }
    }

    sliderInit()

    $(window).on('resize', function() {
      sliderInit()
    })
  })
}
