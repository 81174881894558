import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

class ZitatSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var settings = {
      dots: false,
    }

    return (
      <div className="animated_slider">
        <Slider {...settings}>
          {this.props.slides.map((slide, i) => (
            <div className="item" key={i}>
              <div className="animated_text_out">
                {slide.zitat_avatar ? (
                  <div
                    className="person"
                    dangerouslySetInnerHTML={{ __html: slide.zitat_avatar }}
                  />
                ) : null}
                <div className="animated_text">
                  &#34;{slide.zitat_text}&#34;
                </div>
                {slide.zitat_name ? (
                  <span className="person_name">{slide.zitat_name}</span>
                ) : null}
                {slide.zitat_tweet == 'show' ? (
                  <a
                    onClick={slide.click}
                    className="btn btn-primary"
                    target="_blank"
                  >
                    Tweet this
                  </a>
                ) : null}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

ZitatSlider.propTypes = {
  slides: PropTypes.array,
}

export default ZitatSlider
