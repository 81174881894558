import React from 'react'

class DropInYoutube extends React.Component {
  render() {
    return (
      <div className="drop-in box social youtube active">
        <div className="content">
          <div className="content_inner">
            <h2 className="title">Follow us on YouTube</h2>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/user/InnovationsSoftware"
              className="btn btn-primary"
            >
              Subscribe
            </a>
          </div>
        </div>
      </div>
    )
  }
}

DropInYoutube.propTypes = {}

export default DropInYoutube
