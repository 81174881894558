import React from 'react'
import PropTypes from 'prop-types'
import RecommendedArticle from './RecommendedArticle'

class RecommendedArticles extends React.Component {
  render() {
    const { posts, isLessThanXl, searchActive, searchTermExists } = this.props

    /* only render posts when search is active because the change in
                styles (body.activesearch) otherwise screws with popper.js tooltip positions  */
    if (!(searchActive && searchTermExists)) {
      return null
    }

    return (
      <div className="section recommended">
        <div className="container">
          <span className="indextitle">Recommended Articles</span>
          <div className="row">
            {posts.map((post, idx) => (
              <RecommendedArticle
                key={idx}
                post={post}
                isLessThanXl={isLessThanXl}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

RecommendedArticles.propTypes = {
  posts: PropTypes.array,
  isLessThanXl: PropTypes.bool,
  searchActive: PropTypes.bool,
  searchTermExists: PropTypes.bool,
}

export default RecommendedArticles
