import $ from 'jquery'
// import cookiebar from '../util/cookiebar'
import debounce from 'lodash.debounce'
import '@fancyapps/fancybox'
import 'jquery-match-height'

const bannerInit = () => {
  $(document).ready(function() {
    if ($(window).width() > 768) {
      setTimeout(function() {
        $('.js-left-banner').removeClass('hidden')
      }, 2000)
    } else {
      $('.js-left-banner').removeClass('hidden')
    }
    // if ($(window).width() < 768) {
    //   if (window.localStorage.getItem('closedLeftBanner')) {
    //     $('.js-left-banner').addClass('hidden')
    //   } else {
    //     $('.js-left-banner').removeClass('hidden')
    //   }
    // }
    $('.js-banner-left__toggler').on('click', function() {
      if ($(window).width() < 768) {
        $(this)
          .closest('.banner')
          .toggleClass('closed')
          .toggleClass('opened')

        window.localStorage.setItem('closedLeftBanner', true)
      } else {
        const $banner = $(this).closest('.banner')
        if (
          !$banner.hasClass('closed') &&
          !window.localStorage.getItem('closedLeftBanner')
        ) {
          window.localStorage.setItem('closedLeftBanner', true)
        }
        $banner.toggleClass('closed').toggleClass('opened')
      }
    })

    $('.js-banner-right__toggler').on('click', function() {
      const $banner = $(this).closest('.banner')
      if (
        !$banner.hasClass('closed') &&
        !window.localStorage.getItem('closedRightBanner')
      ) {
        window.localStorage.setItem('closedRightBanner', true)
      }
      $banner.toggleClass('closed').toggleClass('opened')
    })
  })

  const $leftBaner = $('.js-left-banner')

  if (!window.localStorage.getItem('closedLeftBanner')) {
    $leftBaner.removeClass('closed')

    // $(window).on('scroll', function() {
    //   if (
    //     $(window).scrollTop() > 0 &&
    //     !$leftBaner.hasClass('closed') &&
    //     $(window).width() >= 768
    //   ) {
    //     $leftBaner.addClass('closed')
    //   }
    // })
  }

  if (
    $(window).width() < 768 &&
    !window.localStorage.getItem('closedLeftBanner')
  ) {
    $leftBaner.removeClass('closed')
  }
}

export default {
  init() {
    // JavaScript to be fired on all pages
    $('body').addClass('loaded')
    $('.comments-area').click(function() {
      $(this)
        .find('a')
        .attr('target', '_blank')
    })

    $('.comment-form-comment').each(function() {
      var wraper = $(this)
      var field = $(this).find('#comment')
      field.on('keyup cange', function() {
        if ($(this).val() != '') {
          wraper.addClass('hasVal')
        } else {
          wraper.removeClass('hasVal')
        }
      })
      field.focus(function() {
        wraper.addClass('hasFocus')
      })
      field.blur(function() {
        wraper.removeClass('hasFocus')
      })
    })

    let liveLink = getCookie('liveLink')

    if (liveLink === 'yes') {
      liveLink = false
    }

    if (
      ['/live/', '/hackathon/livebcx/'].includes(window.location.pathname) &&
      !liveLink
    ) {
      setCookie('liveLink', window.location.pathname, 2)
      liveLink = window.location.pathname
    }

    if (liveLink) {
      $('header.header .logo a, header.header .bosch-logo a').attr(
        'href',
        liveLink
      )
    }

    // cookiebar()
    dhsvComments()
    mobileMenu()
    bannerInit()

    // let fancyBlock = $('.fancybox, .prettyphoto')
    // fancyBlock.each(function() {
    //   let caption = $(this)
    //     .siblings('.vc_figure-caption')
    //     .html()
    //   $(this).fancybox({
    //     touch: false,
    //     buttons: ['thumbs', 'close'],
    //     minHeight: 0,
    //     caption: caption,
    //     helpers: {
    //       overlay: {
    //         locked: false,
    //       },
    //     },
    //   })
    // })

    // wir warten ganz kurz damit, den Event-Handler anzuschließen damit,
    // wenn man runtergescrollt hat, die seite neu lädt, das erste scroll-event
    // übersprungen wird. Sonst verschwindert der copyright-hinweis sofort wieder,
    // ohne dass man tatsächlich scrollt
    window.setTimeout(function() {
      $(window).scroll(debounce(handleScroll, 100))
    }, 10)

    function setCookie(name, value, days) {
      var expires = ''
      if (days) {
        var date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toUTCString()
      }
      document.cookie = name + '=' + (value || '') + expires + '; path=/'
    }

    function getCookie(name) {
      var nameEQ = name + '='
      var ca = document.cookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
      }
      return null
    }

    function handleScroll() {
      $('.bosch-copyright-notice').addClass('did-scroll')
      $('.cookiebar').addClass('did-scroll')

      const scrollTop = $(window).scrollTop()

      // Sticky Header
      // if (scrollTop > 1) {
      //   $('header.header').addClass('scrolled')
      //   $('.socialtabs').addClass('scrolled')
      // } else {
      //   $('header.header').removeClass('scrolled')
      //   $('.socialtabs').removeClass('scrolled')
      //
      //   // setTimeout(function() {
      //   //   headerOffset()
      //   // }, 400)
      // }
      // Social Tabs
      var singleContent = $('.singlecontent')
      var tabs = $('.socialtabs')
      tabs.each(function() {
        if (
          singleContent.length > 0 &&
          singleContent.offset().top < scrollTop
        ) {
          tabs.addClass('showtabs')
        } else {
          tabs.removeClass('showtabs')
        }
      })
      readingBar()
    }

    function mobileMenuOffset() {
      let menuOffset =
        $('.header .supergraphic').outerHeight() +
        $('.header .header-bottom').outerHeight()
      $('.mobile_menu').css({
        top: menuOffset,
      })
    }

    function throttled(delay, fn) {
      let lastCall = 0
      return function(...args) {
        const now = new Date().getTime()
        if (now - lastCall < delay) {
          return
        }
        lastCall = now
        return fn(...args)
      }
    }

    // DECLARE FUNCTIONS
    function init_sticky_animation() {
      const lastScrollTop = 0
      var scrollTop = undefined
      const header = $('.header .header-bottom')
      const distance = 114
      const leftbanner = $('.left-banner')
      const distancebanner = 115
      const winwidth = $(window).width()

      function handleScroll() {
        scrollTop = $(window).scrollTop()

        // sticky header
        if (scrollTop <= distance) {
          header.removeClass('sticky')
        } else {
          header.addClass('sticky')
        }

        // sticky left banner
        if (scrollTop <= distancebanner) {
          leftbanner.removeClass('sticky')
        } else {
          if (winwidth <= 768) {
            leftbanner.addClass('sticky')
          }
        }

        lastScrollTop
      }

      handleScroll()
      $(window).on('scroll', throttled(10, handleScroll))
    }

    // CALL FUNCTIONS
    init_sticky_animation()

    // function headerOffset() {
    //   $('body').css('padding-top', $('.header').outerHeight())
    // }
    //
    // headerOffset()
    $(window).resize(function() {
      setTimeout(function() {
        // headerOffset()
        if (
          $(window).width() >= 993 &&
          $('.mobile_toggle').hasClass('activetoggle')
        ) {
          $('.mobile_toggle').trigger('click')
        } else {
          mobileMenuOffset()
        }
      }, 500)
    })

    function mobileMenu() {
      let prevScrollOffset = 0
      $('.mobile_toggle').click(function() {
        $('.socialtabs').removeClass('activemenu')
        $('.share_toggle').removeClass('activetoggle')
        if ($(this).hasClass('activetoggle')) {
          $(this).removeClass('activetoggle')
          $('html').removeClass('menuactive')
          $('.mobile_menu').removeClass('activemenu')
          $(document).scrollTop(prevScrollOffset)
        } else {
          prevScrollOffset = $(document).scrollTop()
          $(this).addClass('activetoggle')
          $('html').addClass('menuactive')
          $('.mobile_menu').addClass('activemenu')
          mobileMenuOffset()
        }
      })

      $('.share_toggle').click(function() {
        $('.mobile_menu').removeClass('activemenu')
        $('.mobile_toggle').removeClass('activetoggle')
        if ($(this).hasClass('activetoggle')) {
          $(this).removeClass('activetoggle')
          $('.socialtabs').removeClass('activemenu')
        } else {
          $(this).addClass('activetoggle')
          $('.socialtabs').addClass('activemenu')
        }
      })
    }

    // Main menu hover dropdown
    $('#menu-main-menu .menu-item.menu-item-has-children').hover(
      function() {
        var $that = $(this)
        if (!$that.hasClass('show') && !$('body').hasClass('mobile')) {
          $that
            .addClass('show')
            .children('.sub-menu')
            .stop()
            .fadeIn(350)
        }
      },
      function() {
        var $that = $(this)
        if ($that.hasClass('show') && !$('body').hasClass('mobile')) {
          $that
            .removeClass('show')
            .children('.sub-menu')
            .stop()
            .fadeOut(350)
        }
      }
    )

    function readingBar() {
      var scrollTop = $(window).scrollTop()
      $('.singlecontent').each(function() {
        var h = $(this).height()
        var p1 = 100 / h
        var p2 = p1 * scrollTop
        if (p2 < 100) {
          $('header .blog_duration').css('width', p2 + '%')
          $('header .blog_duration').css('height', '5px')
        } else if (p2 < 120) {
          $('header .blog_duration').css('width', 100 + '%')
          $('header .blog_duration').css('height', '5px')
        } else {
          $('header .blog_duration').css('width', 100 + '%')
          $('header .blog_duration').css('height', '0px')
        }
      })
    }

    function dhsvComments() {
      $('#comments').each(function() {
        var listH = $(this)
          .find('.comments_list')
          .outerHeight()
        var formH = $(this)
          .find('.comment-respond')
          .outerHeight()

        $(this)
          .find('.comments_list')
          .height(0)
        $(this)
          .find('.comment-respond')
          .height(0)

        $('.comments-area').addClass('comments_load')

        $(this)
          .find('.comments_head span')
          .click(function() {
            if (
              $(this)
                .parents('#comments')
                .hasClass('active')
            ) {
              $(this)
                .parents('#comments')
                .removeClass('active')
              $('.comments_list').height(0)
              $('.comment-respond').height(0)
            } else {
              $(this)
                .parents('#comments')
                .addClass('active')
              $('.comments_list').height(listH)
              $('.comment-respond').height(formH)
            }
            $(this)
              .parents('#comments')
              .find('.comment_item')
              .click(function() {
                $('.comments_list').height('auto')
                $('.comment-respond').height('auto')
              })
          })
      })
    }

    function ticketCounter() {
      if ($('.ticket-countdown-wrapper').length) {
        var today = new Date().getTime()
        //Early
        var earlyStart = new Date(
          $('.ticket-countdown-wrapper.early .start').data('start')
        ).getTime()
        var earlyEnd = new Date(
          $('.ticket-countdown-wrapper.early .end').data('end')
        ).getTime()
        if (today > earlyStart && today < earlyEnd) {
          var animationStart = $('.ticket-countdown-wrapper').data('animation')
          $('.ticket-countdown-wrapper.early').removeClass('hidden')
          setTimeout(function() {
            $('.ticket-countdown-wrapper.early').addClass('active')
          }, animationStart)
          var countDate = earlyEnd
        } else if (today > earlyEnd) {
          $('.ticket-countdown-wrapper.early').addClass('hidden')
          $('.ticket-countdown-wrapper.early').removeClass('active')
        }
        //Regular
        var regularStart = new Date(
          $('.ticket-countdown-wrapper.regular .start').data('start')
        ).getTime()
        var regularEnd = new Date(
          $('.ticket-countdown-wrapper.regular .end').data('end')
        ).getTime()
        if (today > regularStart && today < regularEnd) {
          var animationStartReg = $('.ticket-countdown-wrapper').data(
            'animation'
          )
          $('.ticket-countdown-wrapper.regular').removeClass('hidden')
          setTimeout(function() {
            $('.ticket-countdown-wrapper.regular').addClass('active')
          }, animationStartReg)
          countDate = regularEnd
        } else if (today > regularEnd) {
          $('.ticket-countdown-wrapper.regular').addClass('hidden')
          $('.ticket-countdown-wrapper.regular').removeClass('active')
        }
        //Late
        var lateStart = new Date(
          $('.ticket-countdown-wrapper.late .start').data('start')
        ).getTime()
        var lateEnd = new Date(
          $('.ticket-countdown-wrapper.late .end').data('end')
        ).getTime()
        if (today > lateStart && today < lateEnd) {
          var animationStartLate = $('.ticket-countdown-wrapper').data(
            'animation'
          )
          $('.ticket-countdown-wrapper.late').removeClass('hidden')
          setTimeout(function() {
            $('.ticket-countdown-wrapper.late').addClass('active')
          }, animationStartLate)
          countDate = lateEnd
        } else if (today > lateEnd) {
          $('.ticket-countdown-wrapper.late').addClass('hidden')
          $('.ticket-countdown-wrapper.late').removeClass('active')
        }

        setInterval(function() {
          var today = new Date().getTime()

          var distance = countDate - today

          var d = Math.floor(distance / (1000 * 60 * 60 * 24))
          var h = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          )
          var min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
          var sec = Math.floor((distance % (1000 * 60)) / 1000)

          $('.ticket-countdown-wrapper .countdown').html(
            '<div class="ticket-countdown-element col-sm-3 col-3"><span class="ticket-countdown-number h4">' +
              d +
              '</span><br><span class="ticket-countdown-description">Days</span></div><div class="ticket-countdown-element col-sm-3 col-3"><span class="ticket-countdown-number h4">' +
              h +
              '</span><br><span class="ticket-countdown-description">Hours</span></div><div class="ticket-countdown-element col-sm-3 col-3"><span class="ticket-countdown-number h4">' +
              min +
              '</span><br><span class="ticket-countdown-description">Minutes</span></div><div class="ticket-countdown-element col-sm-3 col-3"><span class="ticket-countdown-number h4">' +
              sec +
              '</span><br><span class="ticket-countdown-description">Seconds</span></div>'
          )
        }, 1000)

        $(window).scroll(function() {
          $('.ticket-countdown-wrapper').removeClass('scroll-bottom')
          if (
            $(window).scrollTop() + $(window).height() >
            $(document).height() - 100
          ) {
            $('.ticket-countdown-wrapper').addClass('scroll-bottom')
          }
        })

        $('footer .supergraphic').css('display', 'none')
        $('footer .content-info__bottom').css('padding-bottom', '29px')
      }
    }

    function marginBottom() {
      if ($('.ticket-countdown-wrapper')) {
        var elemnetHeight = $('.ticket-countdown-wrapper').outerHeight()
        $('body').css('margin-bottom', elemnetHeight + 'px')
      }
    }

    $(window).resize(function() {
      ticketCounter()
      marginBottom()
    })
    $(document).ready(function() {
      ticketCounter()
      marginBottom()
    })
    $(document).scroll(function() {
      marginBottom()
    })
  },
  finalize() {
    $('.matchHeight').matchHeight()
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
