import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

class SimpleTextSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      elementHeight: 'auto',
    }
    this.element = React.createRef()
  }

  componentDidMount() {
    let maxHeight = 0
    let i = 0
    const items = this.element.current.querySelectorAll('.item')
    for (i in items) {
      if (items[i]) {
        const thisHeight = items[i].clientHeight
        maxHeight = thisHeight > maxHeight ? thisHeight : maxHeight
      }
    }

    this.setState({
      elementHeight: maxHeight + 50,
    })
  }

  render() {
    const { elementHeight } = this.state
    var autoplay = this.props.autoplay
    var speed = this.props.speed
    var speedplus = speed
    var settings = {
      dots: true,
      arrows: true,
      infinite: true,
      autoplay: autoplay,
      autoplaySpeed: Number(speed),
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      pauseOnHover: false,
      pauseOnFocus: false,
      adaptiveHeight: false,
    }

    return (
      <div className="animated_slider" ref={this.element}>
        <Slider {...settings}>
          {this.props.slides.map((slide, i) => (
            <div className="test" key={i}>
              <div
                className={`bg-gradient bordered item ${slide.background}`}
                style={{
                  height:
                    this.props.sliderheight != "'auto'"
                      ? this.props.sliderheight
                      : elementHeight,
                }}
              >
                <div className="text-wrapper">
                  <div className="animated_text_out">
                    <div className="animated_text">
                      <p className={`headline ${slide.headline_styling}`}>
                        {slide.headline}
                      </p>
                    </div>
                    <p className="person_name">{slide.text}</p>
                  </div>
                </div>
                <div className="progressbar-background">
                  <div
                    className={`progressbar ${slide.linecolor}`}
                    style={{ animationDuration: speedplus + 'ms' }}
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

SimpleTextSlider.propTypes = {
  slides: PropTypes.array,
  autoplay: PropTypes.string,
  speed: PropTypes.number,
  sliderheight: PropTypes.string,
}

export default SimpleTextSlider
