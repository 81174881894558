import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function() {
  dhsv_vc_conference_agenda()
})

function dhsv_vc_conference_agenda() {
  $('.dhsv_vc_conference_agenda').each(function(index, el) {
    const winW = $(window).width()

    if (winW < 768) {
      $(el)
        .find('.conference_agenda-line')
        .each(function() {
          const $item = $(this).find('.conference_agenda-item')

          showTimeLine($($item[1]))
        })
    } else {
      $(el)
        .on('mouseenter', '.conference_agenda-item', function() {
          showTimeLine($(this))
        })
        .on('mouseleave', '.conference_agenda-item', function() {
          $(this)
            .closest('.conference_agenda-lines')
            .find('[data-timeline]')
            .removeClass('active')
        })
    }
  })
}

function showTimeLine($line) {
  const wrapperTop = $line.parents('.conference_agenda-lines').offset().top
  let time = $line.data('time')
  const top = $line.find('span').offset().top - wrapperTop

  let line = $line
    .closest('.conference_agenda-lines')
    .find('[data-timeline="' + time + '"]')
  line.addClass('active').css({ top: top })
}
