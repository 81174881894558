import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../Tooltip'
import { postShape } from '../../shared/shapes'

import './RecommendedArticle.scss'

class RecommendedArticle extends React.Component {
  render() {
    const {
      post: {
        id,
        title: { rendered: title },
        date_display,
        link,
        post_style,
        primary_author: { first_name, last_name },
        color,
        featured,
        primary_category,
        youtube_id,
        reading_time,
      },
      isLessThanXl,
    } = this.props

    const featuredClass = featured ? 'featured' : ''
    const videoClass = youtube_id ? 'video' : ''
    const codeClass = post_style === 'developer' ? 'code' : ''
    const sizeClass = isLessThanXl ? 'col-sm-4' : 'col-sm-3'

    return (
      <div className={sizeClass} data-post-id={id}>
        <a href={link}>
          <div
            className={`box ${color} ${featuredClass} ${videoClass} ${codeClass} recommended-post`}
          >
            <div className="content">
              <div className="content_inner">
                {primary_category ? (
                  <ul className="post-categories">
                    <li>{primary_category['name']}</li>
                  </ul>
                ) : null}
                <p
                  className="title"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                <span className="meta">
                  <ul>
                    <li className="date">
                      {isLessThanXl ? (
                        <span>
                          <i className="boschicons-date" /> {date_display}
                        </span>
                      ) : (
                        <Tooltip tooltip={date_display}>
                          <i className="boschicons-date" />
                        </Tooltip>
                      )}
                    </li>
                    {first_name && last_name && !isLessThanXl ? (
                      <li className="author">
                        <Tooltip tooltip={` ${first_name} ${last_name}`}>
                          <i className="boschicons-author" />
                        </Tooltip>
                      </li>
                    ) : null}
                    <li className="reading-time">
                      {isLessThanXl ? (
                        <span>
                          <i className="boschicons-reading-time" />{' '}
                          {reading_time}
                        </span>
                      ) : (
                        <Tooltip tooltip={reading_time}>
                          <i className="boschicons-reading-time" />
                        </Tooltip>
                      )}
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </a>
      </div>
    )
  }
}

RecommendedArticle.propTypes = {
  post: postShape,
  isLessThanXl: PropTypes.bool,
}

export default RecommendedArticle
