import { connectRoutes } from 'redux-first-router'
import queryString from 'query-string'
import { loadInitialPosts, loadExtraPosts, queryPosts } from './posts'

import {
  FRONTPAGE,
  FRONTPAGE_PAGINATED,
  SEARCH,
  SEARCH_PAGINATED,
  CATEGORY_ARCHIVE,
  CATEGORY_ARCHIVE_PAGINATED,
  AUTHORS,
  AUTHORS_PAGINATED,
  POST,
  sIsInitialPath,
  sCurrentPathLoaded,
} from './routes'

const initialPostsThunk = dispatch => {
  dispatch(loadExtraPosts())
  dispatch(loadInitialPosts())
}

const loadRoutePosts = (dispatch, getState) => {
  const state = getState()
  if (sIsInitialPath(state)) {
    dispatch(initialPostsThunk)
  } else if (!sCurrentPathLoaded(state)) {
    dispatch(queryPosts())
  }
}

export const routesMap = {
  [FRONTPAGE]: {
    path: '/',
    thunk: loadRoutePosts,
  },
  [FRONTPAGE_PAGINATED]: {
    path: '/page/:page',
    thunk: initialPostsThunk,
  },
  [SEARCH]: {
    path: '/search/:search',
    thunk: loadRoutePosts,
  },
  [SEARCH_PAGINATED]: {
    path: '/search/:search/page/:page',
    thunk: initialPostsThunk,
  },
  [AUTHORS]: {
    path: '/author',
    thunk: initialPostsThunk,
  },
  [AUTHORS_PAGINATED]: {
    path: '/author/page/:page',
    thunk: initialPostsThunk,
  },
  [CATEGORY_ARCHIVE]: {
    path: '/:category',
    thunk: loadRoutePosts,
  },
  [CATEGORY_ARCHIVE_PAGINATED]: {
    path: '/:category/page/:page',
    thunk: initialPostsThunk,
  },
  [POST]: {
    path: '/:category/:postname',
    thunk: initialPostsThunk,
  },
}

const router = connectRoutes(routesMap, {
  querySerializer: queryString,
})

export const middleware = router.middleware
export const enhancer = router.enhancer

export default router.reducer
