import $ from 'jquery'

$(document).ready(function() {
  $(window).on('scroll', buttonTopScroll)
  $(window).on('load', buttonTopScroll)

  $('.dhsv_vc_to_top_button').click(function() {
    $('html, body').animate({ scrollTop: 0 }, 600)
    return false
  })
})

function buttonTopScroll() {
  var scrolled = $(window).scrollTop()
  var nav = $('.dhsv_vc_to_top_button')

  if (scrolled >= 200) {
    nav.removeClass('hidebutton')
  } else {
    nav.addClass('hidebutton')
  }
}
