import { connect } from 'react-redux'
import Component from './component'
import splitEvery from 'ramda/es/splitEvery'
import { createSelector } from 'reselect'
import { sPostIdsForCurrentRoute } from '../../redux/modules/postsByRoute'

const WithData = connect(state => ({
  rows: sRowsFromPostIds(state),
  rowHeight: sRowHeight(state),
}))(Component)

export default WithData

const sSplitAt = createSelector(
  state => state.browser.mediaType,
  mediaType => {
    switch (mediaType) {
      case 'xs':
        return 1
      case 'sm':
      case 'md':
      case 'lg':
      case 'xl':
      case 'infinity':
      default:
        return 4
    }
  }
)

const sRowHeight = createSelector(
  state => state.browser.mediaType,
  mediaType => {
    switch (mediaType) {
      case 'xs':
        return 500
      case 'sm':
      case 'md':
        return 302
      case 'lg':
        return 334
      case 'xl':
        return 379
      case 'infinity':
      default:
        return 444
    }
  }
)

const sRowsFromPostIds = createSelector(
  sPostIdsForCurrentRoute,
  sSplitAt,
  (postIds, splitAt) => {
    return splitEvery(splitAt, postIds)
  }
)
