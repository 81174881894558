import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from '../InfiniteScroll'
import Article from '../Article'

import DropInYoutube from '../DropInYoutube'
import DropInTwitter from '../DropInTwitter'
import DropInNewsletter from '../DropInNewsletter'
import DropInTrendingTopics from '../DropInTrendingTopics'
import DropInLinkedIn from '../DropInLinkedIn'

const dropins = {
  youtube: DropInYoutube,
  twitter: DropInTwitter,
  newsletter: DropInNewsletter,
  trendingtopics: DropInTrendingTopics,
  linkedin: DropInLinkedIn,
}

const Item = ({ size, type, value }) => {
  if (type === 'post') {
    return <Article size={size} post_id={value} />
  } else if (type === 'drop_in') {
    const DropIn = dropins[value]
    return (
      <div className="col-xs-12 col-md-6 col-lg-4">
        <DropIn />
      </div>
    )
  }
}

Item.propTypes = {
  size: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
}

export class Row extends React.Component {
  render() {
    const { row, style } = this.props
    return (
      <div className="row flex-row" style={style}>
        {row.map((props, key) => <Item key={key} {...props} />)}
      </div>
    )
  }
}

Row.propTypes = {
  row: PropTypes.array,
  style: PropTypes.object,
}

class FrontPage extends React.Component {
  render() {
    const { rows } = this.props

    return (
      <InfiniteScroll
        style={{ marginTop: '25px' }}
        rows={rows}
        RowComponent={Row}
      />
    )
  }
}

FrontPage.propTypes = {
  rows: PropTypes.array,
}

export default FrontPage
