import $ from 'jquery'
import filter from 'ramda/es/filter'
import identity from 'ramda/es/identity'
import keys from 'ramda/es/keys'
import compose from 'ramda/es/compose'

const classesToRemove = compose(keys, filter(v => !v))
const classesToAdd = compose(keys, filter(identity))

export default function bodyClassController(store) {
  if (!store) {
    return
  }

  let currentValue

  const $body = $('body')

  return store.subscribe(function listen() {
    const previousValue = currentValue
    currentValue = store.getState().bodyClasses

    if (currentValue === previousValue) {
      return
    }

    classesToAdd(currentValue).forEach(c => $body.addClass(c))
    classesToRemove(currentValue).forEach(c => $body.removeClass(c))
  })
}
