import React from 'react'

class DropInLinkedIn extends React.Component {
  render() {
    return (
      <div className="drop-in box social linkedin active">
        <div className="content">
          <div className="content_inner">
            <h2 className="title">Follow us on LinkedIn</h2>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/bosch-software-innovations-corp-"
              className="btn btn-primary"
            >
              Subscribe
            </a>
          </div>
        </div>
      </div>
    )
  }
}

DropInLinkedIn.propTypes = {}

export default DropInLinkedIn
