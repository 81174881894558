import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import Component from './component'

const WithData = connect(state => ({
  options: state.options,
  category: sCurrentCategory(state),
}))(Component)

export default WithData

const sCurrentCategory = createSelector(
  state => state.location.payload.category,
  state => state.categories,
  (categorySlug, categories) => {
    const categoryId = Object.keys(categories).find(
      categoryId => categories[categoryId].slug === categorySlug
    )
    return categoryId ? categories[categoryId] : false
  }
)
