import bodyClassController from '../../util/bodyClassController'
// eslint-disable-next-line
import app from './Bootstrapper'

// activate body class controller
bodyClassController(app.getStore())

if (process.env.NODE_ENV !== 'production') {
  window.app = app
}
