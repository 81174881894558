import PropTypes from 'prop-types'

export const postShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.shape({
    rendered: PropTypes.string.isRequired,
  }),
  date_display: PropTypes.string.isRequired,
  post_thumbnail: PropTypes.shape({
    box_small: PropTypes.string.isRequired,
    box_medium: PropTypes.string.isRequired,
    box_large: PropTypes.string.isRequired,
  }),
  link: PropTypes.string.isRequired,
  post_style: PropTypes.string.isRequired,
  primary_author: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
  color: PropTypes.string,
  dont_crop_featured_image: PropTypes.bool,
  featured: PropTypes.bool,
  featured_source: PropTypes.string,
  excerpt: PropTypes.string,
  primary_category: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  youtube_id: PropTypes.string,
  reading_time: PropTypes.string.isRequired,
})

export const categoryShape = PropTypes.shape({
  name: PropTypes.string,
  link: PropTypes.string,
  hot_topic: PropTypes.bool,
  term_id: PropTypes.number,
})
