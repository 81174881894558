import $ from 'jquery'
import shareImage from '../util/shareImage'
import shareSelection from '../util/shareSelection'

function goToCommentApprovalNotice() {
  const $notice = $('#wait_approval')
  if ($notice.length) {
    window.location.hash = '#wait_approval'
  }
}

export default {
  init() {
    shareImage()
    shareSelection()
    goToCommentApprovalNotice()
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
}
