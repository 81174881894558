import curry from 'ramda/es/curry'
import compose from 'ramda/es/compose'
import join from 'ramda/es/join'
import map from 'ramda/es/map'
import filter from 'ramda/es/filter'
import toPairs from 'ramda/es/toPairs'
import sortBy from 'ramda/es/sortBy'
import prop from 'ramda/es/prop'
import head from 'ramda/es/head'
import tail from 'ramda/es/tail'
import toUpper from 'ramda/es/toUpper'

// contains :: string -> string -> bool
export const contains = curry((q, str) => {
  return str.indexOf(q) !== -1
})

// startsWith :: string -> string -> bool
export const startsWith = curry((q, str) => {
  return str.startsWith(q)
})

// build query string from an object
// buildQueryString :: object -> string
export const buildQueryString = compose(
  join('&'),
  map(([k, v]) => `${k}=${encodeURIComponent(v)}`),
  filter(([, v]) => !!v),
  sortBy(prop(0)),
  toPairs
)

export const firstToUpper = s => toUpper(head(s)) + tail(s)

export const addTrailingSlash = str => str.replace(/\/?(\?|#|$)/, '/$1')

export const stripTrailingSlashes = str => str.replace(/\/+$/, '')

export const escapeHtml = unsafe =>
  unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
