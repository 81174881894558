import React from 'react'
import PropTypes from 'prop-types'

import SearchBar from '../SearchBar'
import SearchProposal from '../SearchSuggestions'
import RecommendedArticles from '../RecommendedArticles'

class LiveSearch extends React.Component {
  constructor() {
    super()

    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false)
    document.addEventListener('keydown', this.handleKeyDown, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false)
    document.removeEventListener('keydown', this.handleKeyDown, true)
  }

  handleOutsideClick(e) {
    if (this.node.contains(e.target) || !this.props.active) {
      return
    }

    this.props.deactivate()
  }

  handleKeyDown(e) {
    const {
      active,
      isSuggestionSelected,
      updateSelection,
      deactivate,
      activateCurrentSelection,
    } = this.props
    if (!active) {
      return true
    }

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault()
        updateSelection(false)
        break
      case 'ArrowDown':
        e.preventDefault()
        updateSelection(true)
        break
      case 'Escape':
      case 'Tab':
        e.preventDefault()
        deactivate()
        break
      case 'Enter':
        if (isSuggestionSelected) {
          e.preventDefault()
          activateCurrentSelection()
        }
        break
      default:
    }
  }

  render() {
    return (
      <div className="live-search" ref={node => (this.node = node)}>
        <SearchBar />
        <SearchProposal />
        <RecommendedArticles />
      </div>
    )
  }
}

LiveSearch.propTypes = {
  active: PropTypes.bool,
  deactivate: PropTypes.func,
  updateSelection: PropTypes.func,
  activateCurrentSelection: PropTypes.func,
  isSuggestionSelected: PropTypes.bool,
}

export default LiveSearch
