import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

class TextSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var settings = {
      dots: false,
      slide: 'div',
    }

    return (
      <div className="animated_slider">
        <Slider {...settings}>
          {this.props.slides.map((slide, key) => (
            <div key={key} dangerouslySetInnerHTML={{ __html: slide }} />
          ))}
        </Slider>
      </div>
    )
  }
}

TextSlider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.string),
}

export default TextSlider
