import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {
  AUTHORS,
  AUTHORS_PAGINATED,
  CATEGORY_ARCHIVE,
  CATEGORY_ARCHIVE_PAGINATED,
  FRONTPAGE,
  FRONTPAGE_PAGINATED,
  SEARCH,
  SEARCH_PAGINATED,
} from '../../redux/modules/routes'
import AuthorArchive from '../AuthorArchive'
import CategoryArchive from '../CategoryArchive'
import FrontPage from '../FrontPage'
import SearchArchive from '../SearchArchive'

class AppContent extends React.Component {
  constructor(props) {
    super(props)
    this.getRouteComponent = this.getRouteComponent.bind(this)
  }

  getRouteComponent() {
    const { currentRoute } = this.props
    switch (currentRoute) {
      case AUTHORS:
      case AUTHORS_PAGINATED:
        return AuthorArchive

      case CATEGORY_ARCHIVE:
      case CATEGORY_ARCHIVE_PAGINATED:
        return CategoryArchive

      case FRONTPAGE:
      case FRONTPAGE_PAGINATED:
        return FrontPage

      case SEARCH:
      case SEARCH_PAGINATED:
        return SearchArchive
      default:
    }
    return null
  }

  render() {
    const { title } = this.props

    const RouteComponent = this.getRouteComponent()

    return (
      <Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <RouteComponent />
      </Fragment>
    )
  }
}

AppContent.propTypes = {
  currentRoute: PropTypes.string,
  title: PropTypes.string,
}

export default AppContent
