import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from '../InfiniteScroll'
import Author from '../Author'

class Row extends React.Component {
  render() {
    const { row, style } = this.props
    return (
      <div
        className="row author-wrapper"
        style={{ ...style, overflow: 'visible' }}
      >
        {row.map(postId => <Author post_id={postId} key={postId} />)}
      </div>
    )
  }
}

Row.propTypes = {
  row: PropTypes.array,
  style: PropTypes.object,
}

class AuthorArchive extends React.Component {
  render() {
    const { rows, rowHeight } = this.props

    return [
      <div className="row singlecontent author_content" key={0}>
        <div className="col-sm-12">
          <h1 style={{ paddingBottom: '24px' }}>Authors</h1>
        </div>
      </div>,
      <InfiniteScroll
        style={{ overflow: 'visible' }}
        containerStyle={{ overflow: 'visible' }}
        rows={rows}
        RowComponent={Row}
        rowHeight={rowHeight}
        threshold={1}
        key={1}
      />,
    ]
  }
}

AuthorArchive.propTypes = {
  rows: PropTypes.array,
  rowHeight: PropTypes.number,
}

export default AuthorArchive
