import React from 'react'
import PropTypes from 'prop-types'
import { Manager, Reference, Popper } from 'react-popper'
import './styles.scss'

class Tooltip extends React.Component {
  constructor(props) {
    super(props)

    this.state = { visible: false }
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  handleMouseEnter() {
    this.setState({ visible: true })
  }

  handleMouseLeave() {
    this.setState({ visible: false })
  }

  render() {
    const { children, tooltip, boundariesElement } = this.props
    const { visible } = this.state

    const modifiers = boundariesElement
      ? { preventOverflow: { boundariesElement } }
      : {}

    const visibilityClass = visible ? 'visible' : 'hidden'

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              ref={ref}
              className="with-tooltip"
            >
              {children}
            </div>
          )}
        </Reference>
        <Popper modifiers={modifiers} placement="top" eventsEnabled={true}>
          {({ ref, style, placement, arrowProps }) => (
            <div
              className={`tooltip ${visibilityClass}`}
              ref={ref}
              style={style}
              data-placement={placement}
            >
              {tooltip}
              <div
                className="arrow"
                ref={arrowProps.ref}
                style={arrowProps.style}
              />
            </div>
          )}
        </Popper>
      </Manager>
    )
  }
}

Tooltip.propTypes = {
  children: PropTypes.node,
  tooltip: PropTypes.node,
  boundariesElement: PropTypes.ref,
}

export default Tooltip
