import values from 'ramda/es/values'
import { createSelector } from 'reselect'

const initialState = {}
export default (state = initialState, action) => {
  switch (action.type) {
    default:
  }
  return state
}

export const sAllTags = createSelector(state => state.tags, values)
