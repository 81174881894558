import { createSelector } from 'reselect'
import {
  AUTHORS,
  AUTHORS_PAGINATED,
  CATEGORY_ARCHIVE,
  CATEGORY_ARCHIVE_PAGINATED,
  FRONTPAGE,
  FRONTPAGE_PAGINATED,
} from './routes'
import { getRouteKey, sRouteKey } from './postsByRoute'

const initialState = {}
export default (state = initialState, action) => {
  const { payload: { page = 0 } = {} } = action
  switch (action.type) {
    case AUTHORS:
    case AUTHORS_PAGINATED:
    case CATEGORY_ARCHIVE:
    case CATEGORY_ARCHIVE_PAGINATED:
    case FRONTPAGE:
    case FRONTPAGE_PAGINATED:
      if (typeof state[getRouteKey(action)] !== 'number') {
        return {
          ...state,
          [getRouteKey(action)]: page,
        }
      }
      return state

    default:
  }
  return state
}

export const sInitialPage = createSelector(
  sRouteKey,
  state => state.initialPage,
  (key, initialPage) => initialPage[key] || 0
)
