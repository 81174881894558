import { connect } from 'react-redux'
import Component from './component'
import { createSelector } from 'reselect'
import { sCurrentCategory } from '../../redux/modules/categories'
import {
  sIsCategory,
  sIsSearch,
  sIsAuthors,
  sIsFrontpage,
} from '../../redux/modules/posts'
import { sTotalPages } from '../../redux/modules/totalPages'

const WithData = connect(state => ({
  currentRoute: state.location.type,
  title: sTitle(state),
}))(Component)

export default WithData

const sBlogname = state => state.options.blogname

const sPageText = createSelector(
  state => state.location.payload,
  sTotalPages,
  ({ page }, totalPages) => (page ? ` Page ${page} of ${totalPages}` : '')
)

const sTaxonomySeoTitle = createSelector(
  sBlogname,
  sCurrentCategory,
  (blogname, { wpseo_title } = {}) => `${wpseo_title || ''} - ${blogname}`
)

const sSearchTitle = createSelector(
  sBlogname,
  state => state.location.payload,
  (blogname, { search }) => `Your search for ${search} - ${blogname}`
)

const sAuthorsTitle = createSelector(
  sBlogname,
  sPageText,
  (blogname, pageText) => {
    return `Authors Archive${pageText} - ${blogname}`
  }
)

const sFrontpageTitle = createSelector(
  sBlogname,
  sPageText,
  (blogname, pageText) => {
    return `Internet of Things - ${blogname}${pageText}`
  }
)

const sTitle = createSelector(
  sIsCategory,
  sIsSearch,
  sIsAuthors,
  sIsFrontpage,
  sTaxonomySeoTitle,
  sSearchTitle,
  sAuthorsTitle,
  sFrontpageTitle,
  (
    isCategory,
    isSearch,
    isAuthors,
    isFrontpage,
    taxonomySeoTitle,
    searchTitle,
    authorsTitle,
    frontpageTitle
  ) => {
    if (isCategory) {
      return taxonomySeoTitle
    } else if (isSearch) {
      return searchTitle
    } else if (isAuthors) {
      return authorsTitle
    } else if (sIsFrontpage) {
      return frontpageTitle
    } else {
      return ''
    }
  }
)
