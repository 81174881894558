import { connect } from 'react-redux'
import Component from './component'
import splitEvery from 'ramda/es/splitEvery'
import { createSelector } from 'reselect'
import { sPostIdsForCurrentRoute } from '../../redux/modules/postsByRoute'
import { sLayoutColumns } from '../../redux/modules/browser'
import {
  sSearchStringFromLocation,
  sIsEmptySearchResult,
} from '../../redux/modules/search'

const WithData = connect(state => ({
  rows: sRowsFromPostIds(state),
  searchString: sSearchStringFromLocation(state),
  isEmptySearchResult: sIsEmptySearchResult(state),
}))(Component)

export default WithData

const sRowsFromPostIds = createSelector(
  sPostIdsForCurrentRoute,
  sLayoutColumns,
  (postIds, layoutColumns) => splitEvery(layoutColumns, postIds)
)
