import { createSelector } from 'reselect'

import Fuse from 'fuse.js'

import prop from 'ramda/es/prop'
import map from 'ramda/es/map'
import filter from 'ramda/es/filter'
import compose from 'ramda/es/compose'
import unapply from 'ramda/es/unapply'
import unnest from 'ramda/es/unnest'
import take from 'ramda/es/take'
import toLower from 'ramda/es/toLower'
import contains from 'ramda/es/contains'

import { sAllCategories } from './categories'
import { sAllTags } from './tags'
import { sInputString } from './search'

const initialState = {}
export default (state = initialState, action) => {
  switch (action.type) {
    default:
  }
  return state
}

export const sAllSearchSuggestions = state => state.searchSuggestions

const sWpTerms = createSelector(sAllCategories, sAllTags, unapply(unnest))

const sWpTermsNames = createSelector(
  sWpTerms,
  map(compose(toLower, prop('name')))
)

const sSearchSuggestionObjs = createSelector(
  sAllSearchSuggestions,
  sWpTermsNames,
  (allSearchSuggestions, wpTermsNames) =>
    compose(
      map(name => ({
        name,
      })),
      filter(n => !contains(toLower(n), wpTermsNames))
    )(allSearchSuggestions)
)

const sAllTerms = createSelector(
  sWpTerms,
  sSearchSuggestionObjs,
  unapply(unnest)
)

const excludeTerms = ['unkategorisiert']

const sAllTermsFiltered = createSelector(
  sAllTerms,
  filter(({ slug }) => excludeTerms.indexOf(slug) === -1)
)

const sFuse = createSelector(
  sAllTermsFiltered,
  terms =>
    // http://fusejs.io/
    new Fuse(terms, {
      shouldSort: true,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0.2,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 0,
      keys: ['name'],
    })
)

export const sSuggestionsResult = createSelector(
  sFuse,
  sInputString,
  (fuse, searchTerm) => fuse.search(searchTerm.trim())
)

export const sSuggestions = createSelector(
  sSuggestionsResult,
  compose(map(compose(toLower, prop('name'))), take(6))
)

export const sHaveSuggestions = createSelector(
  sSuggestionsResult,
  result => result.length > 0
)
