import React from 'react'
import PropTypes from 'prop-types'

class DropInTrendingTopics extends React.Component {
  render() {
    const { category, options: { seo_box_h1, seo_box_bodytext } } = this.props
    const categoryHeadline =
      category && category.headline ? category.headline : ''
    const categoryDescription =
      category && category.description ? category.description : ''
    return (
      <div className="drop-in box trending-topics active social">
        <div className="content">
          <div className="content_inner">
            <h1 className="title">
              {category ? categoryHeadline : seo_box_h1}
            </h1>
            <p
              dangerouslySetInnerHTML={{
                __html: category ? categoryDescription : seo_box_bodytext,
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

DropInTrendingTopics.propTypes = {
  options: PropTypes.object,
  category: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
}

export default DropInTrendingTopics
