import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

class StoryStage extends React.Component {
  constructor(props) {
    super(props)

    this.state = { active: false }

    this.handleClickOpen = this.handleClickOpen.bind(this)
    this.handleClickClose = this.handleClickClose.bind(this)
  }

  handleClickOpen() {
    this.setState({ active: true })
  }

  handleClickClose() {
    this.setState({ active: false })
  }

  render() {
    const { youtubeId, imageUrl, source } = this.props
    const { active } = this.state
    return (
      <div
        className={'story_stage has-video' + (active ? ' video_active' : '')}
        style={active ? {} : { backgroundImage: `url(${imageUrl})` }}
        onClick={active ? x => x : this.handleClickOpen}
      >
        {source ? <div className="source">Source: {source}</div> : null}
        <div className="playbutton">
          <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 100">
            <circle className="circle" cx="50" cy="50" r="47.5" />
            <path
              className="play"
              d="M42,38.7l19.2,11.9L42,61.6V38.7 M37,29.8v40.5l34-19.4L37,29.8L37,29.8z"
            />
            <g className="pause">
              <line className="st0" x1="37" y1="70.2" x2="37" y2="29.8" />
              <line className="st0" x1="63" y1="70.2" x2="63" y2="29.8" />
            </g>
          </svg>
        </div>
        {active ? (
          <div className="youtube_wrapper">
            <iframe
              src={`https://www.youtube-nocookie.com/embed/${youtubeId}?autoplay=1&showinfo=0&rel=0`}
              frameBorder="0"
              allowFullScreen
            />
            <div className="close_yt" onClick={this.handleClickClose}>
              <i className="boschicons-close" />
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

StoryStage.propTypes = {
  youtubeId: PropTypes.string,
  imageUrl: PropTypes.string,
  source: PropTypes.string,
}

export default StoryStage
