/*eslint-disable*/
var li = $('.tab_container')
  .find('li.tab-li')
  .insertBefore('.tab_container')
li.wrapAll('<ul class="tab-box">')

$('.tab-box .accordion_title').on('click', function(e) {
  e.preventDefault()

  $(this)
    .parent()
    .addClass('active')
  $(this)
    .parent()
    .siblings()
    .removeClass('active')

  var target = $(this).attr('href')

  $('.tab_container > div')
    .not(target)
    .hide()

  $(target).fadeIn(600)
})
