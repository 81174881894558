import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_new()
})

function dhsv_vc_new() {
  $('.dhsv_vc_progress').each(function(index, el) {
    // let $wrapper = $(el)
    let $slider = $(el).find('.progress-slider')
    let sliderSpeed = $slider.attr('data-speed')
    let sliderAutoplay = $slider.attr('data-autoplay')

    function startStatusBar(slider) {
      slider
        .closest('.dhsv_vc_progress')
        .find('[data-progress-bar]')
        .stop()
        .css('width', '')
        .animate(
          {
            width: '100%',
          },
          Number(sliderSpeed)
        )
    }

    $slider
      .on('init', function(event, slick) {
        startStatusBar(slick.$slider)
      })
      .on('beforeChange', function(event, slick) {
        startStatusBar(slick.$slider)
      })

    $slider.slick({
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: sliderAutoplay,
      autoplaySpeed: Number(sliderSpeed),
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
    })
  })
}
