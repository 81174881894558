import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function() {
  dhsv_vc_speakers_slider()
})

$(window).load(function() {
  dhsv_vc_speakers_slider_load()
})

function dhsv_vc_speakers_slider_load() {
  $('.dhsv_vc_speakers_slider').each(function(index, el) {
    let $slider = $(el).find('.speakers_slider')
    let sliderHeight = $slider.outerHeight()
    let currentHeight = $slider
      .find('.slick-current .speaker-item__inner')
      .outerHeight()
    let sliderPadding = currentHeight - sliderHeight

    $slider.css({
      paddingTop: sliderPadding / 2 + 15,
      paddingBottom: sliderPadding / 2 + 15,
    })

    $(window).on('resize', function() {
      $slider.css({
        paddingTop: sliderPadding / 2 + 15,
        paddingBottom: sliderPadding / 2 + 15,
      })
    })
  })
}

function dhsv_vc_speakers_slider() {
  $('.dhsv_vc_speakers_slider').each(function(index, el) {
    let $slider = $(el).find('.speakers_slider')
    let sliderSpeed = $slider.attr('data-speed')

    // function startStatusBar() {
    //   const $bar = $slider.find('[data-progress-bar]')
    //   $bar.stop().css('width', 0)
    //   $bar.animate(
    //     {
    //       width: '100%',
    //     },
    //     Number(sliderSpeed)
    //   )
    //
    //   $('.dhsv_vc_speakers_slider').mouseover(function() {
    //     $bar.clearQueue()
    //     $bar.stop()
    //   })
    //   $('.dhsv_vc_speakers_slider').mouseout(function() {
    //     $bar.width(0)
    //     $bar.animate(
    //       {
    //         width: '100%',
    //       },
    //       Number(sliderSpeed)
    //     )
    //   })
    // }

    function setSlidesClass(slick, currentSlide) {
      slick.$slider
        .find('.slick-slide')
        .removeClass('second-size second-prev second-next current-slide')

      slick.$slider.find('.progress-line span').css({
        transitionDuration: 0,
      })

      slick.$slides
        .eq(currentSlide)
        .addClass('current-slide')
        .prev()
        .addClass('second-size second-prev')

      slick.$slides
        .eq(currentSlide)
        .next()
        .addClass('second-size second-next')

      slick.$slides
        .eq(currentSlide)
        .find('.progress-line span')
        .css({
          transitionDuration: Number(sliderSpeed),
        })
    }

    function setSlidesClassInit(slick, currentSlide) {
      slick.$slides
        .eq(currentSlide)
        .addClass('current-slide')
        .prev()
        .addClass('second-size second-prev')
      slick.$slides
        .eq(currentSlide)
        .next()
        .addClass('second-size second-next')
    }

    $slider
      .on('init', function(event, slick) {
        setSlidesClassInit(slick, 0)
        // startStatusBar(slick.$slides.eq(0))
      })
      .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        setSlidesClass(slick, nextSlide)
      })
    // .on('afterChange', function(event, slick, currentSlide, nextSlide) {
    //   startStatusBar(slick.$slides.eq(nextSlide))
    // })

    let sliderAutoplay = $slider.attr('data-autoplay')
    let sliderShow = $slider.attr('data-slide-show')
    let sliderCount = $slider.attr('data-slide-count')
    let centerPadding = 0

    let sliderArgs = {
      dots: false,
      arrows: false,
      infinite: true,
      centerMode: true,
      centerPadding: centerPadding,
      focusOnSelect: true,
      swipe: false,
      autoplay: sliderAutoplay,
      autoplaySpeed: Number(sliderSpeed),
      slidesToShow: Number(sliderShow),
      slidesToScroll: 1,
      pauseOnHover: true,
      pauseOnFocus: false,
      cssEase: 'ease-in-out',
      easing: 'linear',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            swipe: true,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            swipe: true,
            centerMode: false,
            centerPadding: 0,
          },
        },
      ],
    }

    if (Number(sliderCount) > Number(sliderShow)) {
      $slider.slick(sliderArgs)
    }
  })
}
