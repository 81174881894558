// import external dependencies
import 'jquery'

// import everything from autoload
import './autoload/_bootstrap.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/button/main.js'; import '../vc_elements/conference_agenda/main.js'; import '../vc_elements/contentbox/main.js'; import '../vc_elements/countdown/main.js'; import '../vc_elements/faq/main.js'; import '../vc_elements/hero_section/main.js'; import '../vc_elements/partners_slider/main.js'; import '../vc_elements/programs_filter/main.js'; import '../vc_elements/progress/main.js'; import '../vc_elements/slider/main.js'; import '../vc_elements/speakers_slider/main.js'; import '../vc_elements/tab_container/main.js'; import '../vc_elements/teaser_slider/main.js'; import '../vc_elements/template_element/main.js'; import '../vc_elements/timetable/main.js'; import '../vc_elements/title_section/main.js'; import '../vc_elements/to_top_button/main.js'; import '../vc_elements/updates/main.js' // eslint-disable-line

// start forwarding utm query params
import './init/utm-forward'

import './init/gtm'

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'
import singlePost from './routes/singlePost'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // blog posts
  singlePost,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())
