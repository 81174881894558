import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
}

const LOAD_CONFERENCE_PROGRAM =
  'dhsv/bos001/conferenceProgram/LOAD_CONFERENCE_PROGRAM'
const LOAD_CONFERENCE_PROGRAM_SUCCESS =
  'dhsv/bos001/conferenceProgram/LOAD_CONFERENCE_PROGRAM_SUCCESS'
const LOAD_CONFERENCE_PROGRAM_FAILURE =
  'dhsv/bos001/conferenceProgram/LOAD_CONFERENCE_PROGRAM_FAILURE'

export default (state = initialState, action) => {
  const { type, error, json } = action
  switch (type) {
    case LOAD_CONFERENCE_PROGRAM:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_CONFERENCE_PROGRAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: json,
      }

    case LOAD_CONFERENCE_PROGRAM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error,
      }
    default:
      return state
  }
}

export const loadConferenceProgram = () => (dispatch, getState) => {
  dispatch({ type: LOAD_CONFERENCE_PROGRAM })

  if (getState().conferenceProgram.isLoaded) return Promise.resolve()

  return fetch(`${WP_API_BASE}programs`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(json => {
      dispatch({
        json,
        type: LOAD_CONFERENCE_PROGRAM_SUCCESS,
      })
      return json
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_CONFERENCE_PROGRAM_FAILURE,
      })
    })
}
