import React from 'react'
import PropTypes from 'prop-types'

class SearchBar extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidUpdate(prevProps) {
    const { active: wasActive } = prevProps
    const { active } = this.props

    if (wasActive && !active) {
      this.searchInput.blur()
    }
  }

  render() {
    const {
      activate,
      active,
      inputString,
      updateInput,
      gotoSearch,
    } = this.props
    return (
      <div className={'section search' + (active ? ' active' : '')}>
        <div className="container">
          <form
            action="/"
            method="get"
            onSubmit={e => {
              e.preventDefault()
              const { inputString } = this.props
              gotoSearch(inputString)
            }}
          >
            <input
              placeholder="What are you looking for?"
              name="s"
              type="text"
              ref={node => (this.searchInput = node)}
              value={inputString}
              autoComplete="off"
              onFocus={activate}
              onChange={({ currentTarget: { value } }) => updateInput(value)}
            />
          </form>
          <i
            className="boschicons-search"
            aria-hidden="true"
            onClick={activate}
          />
        </div>
      </div>
    )
  }
}

SearchBar.propTypes = {
  activate: PropTypes.func,
  inputString: PropTypes.string,
  updateInput: PropTypes.func,
  gotoSearch: PropTypes.func,
  active: PropTypes.bool,
}

export default SearchBar
