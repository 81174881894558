import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSelector } from 'reselect'
import Fuse from 'fuse.js'

import map from 'ramda/es/map'
import pathOr from 'ramda/es/pathOr'
import compose from 'ramda/es/compose'
import take from 'ramda/es/take'
import uniqBy from 'ramda/es/uniqBy'
import prop from 'ramda/es/prop'
import propOr from 'ramda/es/propOr'
import unapply from 'ramda/es/unapply'
import unnest from 'ramda/es/unnest'
import descend from 'ramda/es/descend'
import sortWith from 'ramda/es/sortWith'
import join from 'ramda/es/join'

import Component from './component'
import { sSuggestionsResult } from '../../redux/modules/searchSuggestions'
import { sInputString, sSearchTermExists } from '../../redux/modules/search'
import { sAllRecentPosts } from '../../redux/modules/posts'
import { sIsLessThanXl } from '../../redux/modules/browser'

export default connect(
  state => ({
    posts: sRecommendedPosts(state),
    isLessThanXl: sIsLessThanXl(state),
    searchActive: state.search.active,
    searchTermExists: sSearchTermExists(state),
  }),
  dispatch => bindActionCreators({}, dispatch)
)(Component)

const sAllRecentPostsWithCategoryNames = createSelector(
  sAllRecentPosts,
  state => state.categories,
  (posts, categories) =>
    map(post => ({
      ...post,
      categoryNames: compose(
        join(' '),
        map(term_id => categories[term_id].name)
      )(post.categories),
    }))(posts)
)

const sFuse = createSelector(
  sAllRecentPostsWithCategoryNames,
  posts =>
    // http://fusejs.io/
    new Fuse(posts, {
      shouldSort: true,
      tokenize: true,
      // matchAllTokens: true,
      threshold: 0.2,
      location: 0,
      distance: 15,
      findAllMatches: true,
      includeMatches: true,
      maxPatternLength: 32,
      minMatchCharLength: 0,
      keys: ['focus_keyword', 'title.rendered', 'categoryNames'],
    })
)

export const sFocusKeywordRecentPostsResult = createSelector(
  sFuse,
  sInputString,
  (fuse, searchTerm) =>
    map(
      ({ item, matches: { length: keywordMatches } }) => ({
        ...item,
        keywordMatches,
      }),
      fuse.search(searchTerm.trim())
    )
)

export const sSuggestedTermId = createSelector(
  sSuggestionsResult,
  pathOr(null, ['0', 'term_id'])
)

export const sSuggestedTermPosts = createSelector(
  state => state.recentPostIdsByTermId,
  state => state.posts,
  sSuggestedTermId,
  (recentPostIdsByTermId, posts, searchTermId) =>
    searchTermId && recentPostIdsByTermId[searchTermId].length
      ? map(id => posts[id], recentPostIdsByTermId[searchTermId])
      : []
)

const concatAll = unapply(unnest)

export const sUniqueRecommendedPosts = createSelector(
  sFocusKeywordRecentPostsResult,
  sSuggestedTermPosts,
  state => state.recommendedPosts,
  compose(uniqBy(prop('id')), concatAll)
)

export const sSortedPosts = createSelector(
  sUniqueRecommendedPosts,
  sortWith([descend(propOr(0, 'keywordMatches')), descend(prop('date'))])
)

export const sRecommendedPosts = createSelector(
  sIsLessThanXl,
  sSortedPosts,
  (isLessThanXl, posts) => take(isLessThanXl ? 3 : 4)(posts)
)
