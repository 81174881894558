import $ from 'jquery'
import * as bodymovin from 'bodymovin'

function heroAnimation() {
  let animations = new Array()
  var animLength = 90
  var segment = [0, animLength]
  var startFrame = 0
  var endFrame = animLength

  $('.hero-bg[data-animation]').each(function(index, el) {
    animations.push(
      bodymovin.loadAnimation({
        container: el,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: $(el).data('animation'),
        rendererSettings: {
          preserveAspectRatio: 'xMaxYMin meet',
        },
      })
    )
  })

  animations.forEach(function(el) {
    el.addEventListener('complete', animationFinished)
  })

  function createAnimSegment() {
    animations.forEach(function(el) {
      startFrame = endFrame
      endFrame =
        window.pageYOffset >= 0 ? animLength - window.pageYOffset * 0.1 : 0

      segment[0] = startFrame
      segment[1] = endFrame
      el.playSegments(segment, false)
    })
  }

  function animationFinished() {
    createAnimSegment()
  }

  $(window).load(function() {
    animations.forEach(function(el) {
      el.setSpeed(5)
      segment[0] = startFrame
      segment[1] = endFrame
      el.playSegments(segment, false)
    })
  })
}

function heroVideo() {
  // eslint-disable-next-line no-constant-condition,no-cond-assign
  if ($('.dhsv_vc_hero_section .video-bg').length == 0) {
    return
  }

  const videoType =
    $(window).width() > 1024 ? 'hero-video-descktop' : 'hero-video-mobile'

  const video = document.getElementById(videoType)
  const content = document.getElementsByClassName('hero-content--video')

  Array.from(content).forEach(function(item) {
    item.classList.add('play')
    // eslint-disable-next-line no-console
    console.log(video)
  })

  video.play()
  video.classList.add('play')
}

$(document).on('load', heroAnimation())
document.addEventListener('loadeddata', heroVideo())
