import { API_URL, SEARCH_API_URL } from '../../constants.js'
import { normalizeByKey } from '../../util/state'
import { createSelector } from 'reselect'
import { sCurrentCategory } from './categories'

import compose from 'ramda/es/compose'
import sortBy from 'ramda/es/sortBy'
import prop from 'ramda/es/prop'
import reverse from 'ramda/es/reverse'
import map from 'ramda/es/map'
import unnest from 'ramda/es/unnest'
import values from 'ramda/es/values'
import uniq from 'ramda/es/uniq'
import __ from 'ramda/es/__'

import {
  sCurrentPathLoaded,
  sIsInitialPath,
  AUTHORS,
  AUTHORS_PAGINATED,
  SEARCH,
  SEARCH_PAGINATED,
  FRONTPAGE,
  FRONTPAGE_PAGINATED,
  CATEGORY_ARCHIVE,
  CATEGORY_ARCHIVE_PAGINATED,
  POST,
} from './routes'
import { buildQueryString, stripTrailingSlashes } from '../../util/string'
import { sTotalPages } from './totalPages'
import { sRouteKey } from './postsByRoute'

export const QUERY_POSTS = 'bos001/posts/QUERY_POSTS'
export const QUERY_POSTS_SUCCESS = 'bos001/posts/QUERY_POSTS_SUCCESS'
export const QUERY_POSTS_FAILURE = 'bos001/posts/QUERY_POSTS_FAILURE'

export const LOAD_INITIAL_POSTS = 'bos001/posts/LOAD_INITIAL_POSTS'
export const LOAD_EXTRA_POSTS = 'bos001/posts/LOAD_EXTRA_POSTS'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case QUERY_POSTS_SUCCESS:
    case LOAD_INITIAL_POSTS:
    case LOAD_EXTRA_POSTS:
      return {
        ...state,
        ...normalizeByKey('id', action.json.result),
      }
    default:
  }
  return state
}

export const queryPosts = () => ({
  types: [QUERY_POSTS, QUERY_POSTS_SUCCESS, QUERY_POSTS_FAILURE],
  callAPI: ({ getState }) => {
    const state = getState()
    const { location: { payload }, options: { posts_per_page } } = state

    const isAuthors = sIsAuthors(state)

    const {
      page = 1,
      per_page = posts_per_page,
      search = '',
      post_type = isAuthors ? 'author' : '',
    } = payload

    const termObj = sCurrentCategory(state)

    const categories = termObj ? termObj['term_id'] : ''

    const isSearch = sIsSearch(state)

    const queryString = buildQueryString({
      categories,
      page,
      s: search,
      ...(isSearch ? { posts_per_page: per_page } : { per_page }),
      ...(isAuthors ? { order: 'asc', orderby: 'title' } : {}),
    })

    const base = post_type ? post_type : 'posts'

    const URL = isSearch
      ? `${SEARCH_API_URL}?${queryString}`
      : `${API_URL}/${base}?${queryString}`

    return fetch(URL, {
      method: 'GET',
      credentials: 'include',
    })
  },
})

export const loadInitialPosts = () => (dispatch, getState) => {
  const state = getState()
  const { location: { pathname } } = state

  const json = window.dhsv_redux_initial_state.posts

  if (sIsInitialPath(state) && json && Array.isArray(json.result)) {
    dispatch({
      type: LOAD_INITIAL_POSTS,
      pathname,
      json,
      dispatchLocation: state.location,
    })
    return true
  }
  return false
}

export const loadExtraPosts = () => (dispatch, getState) => {
  const state = getState()

  const { recentPostsForAllTerms } = window.dhsv_redux_initial_state

  if (sIsInitialPath(state) && Array.isArray(recentPostsForAllTerms)) {
    dispatch({
      type: LOAD_EXTRA_POSTS,
      json: {
        result: recentPostsForAllTerms,
      },
    })
    return true
  }
  return false
}

export const sAllRecentPosts = createSelector(
  state => state.posts,
  state => state.recentPostIdsByTermId,
  (posts, recentPostIdsByTermId) =>
    compose(map(prop(__, posts)), uniq, unnest, values)(recentPostIdsByTermId)
)

export const sAllRecentPostsByDate = createSelector(
  sAllRecentPosts,
  compose(reverse, sortBy(prop('date')))
)

export const sPostIdsForCurrentPath = createSelector(
  state => state.location.pathname,
  state => state.postsByPath,
  sCurrentPathLoaded,
  (pathname, postsByPath, currentPathLoaded) =>
    currentPathLoaded ? postsByPath[stripTrailingSlashes(pathname)] : false
)

export const sHasNextPage = createSelector(
  sTotalPages,
  sRouteKey,
  state => state.postsByRoute,
  (totalPages, key, postsByRoute) =>
    totalPages !== 1 && (!postsByRoute[key] || !postsByRoute[key][totalPages])
)

export const sIsLastPage = createSelector(
  sTotalPages,
  state => state.location.payload,
  (totalPages, { page }) => (page ? page === totalPages : totalPages === 1)
)

export const sIsSearch = createSelector(
  state => state.location.type,
  type => [SEARCH, SEARCH_PAGINATED].indexOf(type) !== -1
)

export const sIsAuthors = createSelector(
  state => state.location.type,
  type => [AUTHORS, AUTHORS_PAGINATED].indexOf(type) !== -1
)

export const sIsFrontpage = createSelector(
  state => state.location.type,
  type => [FRONTPAGE, FRONTPAGE_PAGINATED].indexOf(type) !== -1
)

export const sIsCategory = createSelector(
  state => state.location.type,
  type => [CATEGORY_ARCHIVE, CATEGORY_ARCHIVE_PAGINATED].indexOf(type) !== -1
)

export const sIsPost = createSelector(
  state => state.location.type,
  type => type === POST
)
